<template>
    <LayoutPage screen-name="dashboard">
        <template #pageTitle>
            <div>{{ $t('pages.homeV2.title') }}</div>
        </template>
        <template #mobileHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem
                        class="pr-[10px]"
                        data-test="header-user-name"
                        button
                        @click="openGlobalPage('userNavigationToggled')"
                    >
                        <Avatar :src="profileImage && profileImage.url" size="small" />
                    </HeaderBarItem>
                </template>
                <template #body>
                    <HeaderBarItem center no-x-padding>
                        <SchuettflixLogo class="img-responsive" />
                    </HeaderBarItem>
                </template>
                <template #right>
                    <HeaderBarItem class="relative" button data-test="resource-center" @click="openResourceCenter">
                        <HelpIcon class="relative right-[11px] top-[3px]" />
                    </HeaderBarItem>
                    <HeaderBarItem class="relative" button @click="openGlobalPage('page.notifications')">
                        <NotificationBellIcon class="relative right-[11px] top-[3px] opacity-40" />
                        <span
                            v-if="unreadCount"
                            class="font-copy-sm-strong absolute right-[15px] top-[3px] block h-[20px] w-[20px] rounded-full bg-brand text-center leading-5 text-white"
                            >{{ unreadCount }}</span
                        >
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <div
            v-if="!isUserPending"
            class="mx-4 mb-8 mt-4 max-w-screen-lg lg:mx-auto"
            :class="{
                'mx-auto my-0 grid h-full items-center justify-center': displayComingSoonMessage,
            }"
        >
            <div v-if="displayComingSoonMessage">
                <InfoMessage
                    transparent
                    :title="$t('pages.home.comingSoon.title')"
                    :text="$t('pages.home.comingSoon.text', { supportPhone: servicePhone })"
                >
                    <template #icon>
                        <TruckIcon />
                    </template>
                </InfoMessage>
            </div>
            <div v-else>
                <template v-if="$can('haveClientOrders')">
                    <FavoriteProjectsSection />
                </template>
                <template v-else>
                    <HomeSectionOrder
                        v-if="$can('createAnyQuoteInCheckout')"
                        :key="`order-${currentUserId}`"
                        class="mb-[60px] lg:mb-[100px]"
                        :class="{ 'mb-4': !$can('createAnyQuoteInCheckout') }"
                    />
                </template>

                <InfoMessage
                    v-if="displayOrderingNotAvailableMessage"
                    :title="$t('pages.home.orderingNotAvailable.title')"
                    :text="$t('pages.home.orderingNotAvailable.text', { supportPhone: servicePhone })"
                >
                    <template #icon>
                        <WarningInfoIcon />
                    </template>
                </InfoMessage>

                <PLBannner
                    v-if="market && market.code === 'PL' && organizationTypes && organizationTypes.includes('carrier')"
                />

                <HomeSectionLogistic
                    v-if="$can('listActivePickupTrips') || $can('listActiveTrips') || $can('haveTransportHub')"
                    :key="`logistic-${currentUserId}`"
                    class="mb-12 lg:mb-[100px]"
                />
                <HomeSectionPickup
                    v-if="$can('listActivePickupTrips')"
                    :key="`pickup-${currentUserId}`"
                    class="mb-[60px] lg:mb-[100px]"
                />
                <HomeSectionLoading
                    v-if="$can('listSupplierTransports') || $can('haveCourtList')"
                    :key="`loading-${currentUserId}`"
                    class="mb-[60px] lg:mb-[100px]"
                />
                <HomeSectionDocuments
                    v-if="$can('haveReceipts')"
                    :key="`documents-${currentUserId}`"
                    class="mb-[60px] lg:mb-[100px]"
                />
                <HomeSectionTools
                    v-if="$can('canUseToolTransportCalculation') && isTransportCostCalculatorEnabled"
                    :key="`tools-${currentUserId}`"
                    :currentUserId="currentUserId"
                    class="mb-[60px] lg:mb-[100px]"
                />
                <LanguageMismatchModal v-if="injectLanguageMismatchModal" :user-locale="userLocale" />
            </div>
        </div>
        <Flyout route="home__checkout-main" size="small" no-header />
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import userflow from 'userflow.js';
import { useLd } from '@/services/LaunchDarkly';

import pageMixin from '@/plugins/mixins/pageMixin';

import Avatar from '@/components/Avatar';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LayoutPage from '@/components/Layout/Page.v2';
import InfoMessage from '@/pages/Home/components/InfoMessage';

import HomeSectionOrder from './Sections/HomeSectionOrder';
import HomeSectionLogistic from './Sections/HomeSectionLogistic';
import HomeSectionPickup from './Sections/HomeSectionPickup';
import HomeSectionLoading from './Sections/HomeSectionLoading';
import HomeSectionDocuments from './Sections/HomeSectionDocuments';
import HomeSectionTools from './Sections/HomeSectionTools';
import PLBannner from '@/components/Notification/PLBanner.vue';

import HelpIcon from '@/assets/icons/regular/question.svg';
import NotificationBellIcon from '@/assets/icons/bell.svg';
import SchuettflixLogo from '@/assets/schuettflix_logo.svg';
import TruckIcon from '@/assets/icons/next/truck--96.svg';
import WarningInfoIcon from '@/assets/icons/regular/warning-info--triangle.svg';

import LanguageMismatchModal from '@/pages/Login/LanguageMismatchModal';
import FavoriteProjectsSection from '@/constructionProjects/components/FavoriteProjectSection.vue';
import Flyout from '@/components/Layout/Flyout.vue';

export default {
    name: 'HomePage',
    components: {
        Flyout,
        FavoriteProjectsSection,
        Avatar,
        HeaderBar,
        HeaderBarItem,
        LayoutPage,
        InfoMessage,

        HomeSectionOrder,
        HomeSectionLogistic,
        HomeSectionPickup,
        HomeSectionLoading,
        HomeSectionDocuments,
        HomeSectionTools,
        PLBannner,

        NotificationBellIcon,
        HelpIcon,
        SchuettflixLogo,
        TruckIcon,
        WarningInfoIcon,

        LanguageMismatchModal,
    },
    mixins: [pageMixin],
    setup: () => {
        return {
            isTransportCostCalculatorEnabled: useLd('transport_cost_calculator'),
        };
    },
    computed: {
        ...mapGetters('user', {
            profileImage: 'profileImage',
            currentUserId: 'id',
            isClient: 'isClient',
            isInfoPending: 'isInfoPending',
            userLocale: 'locale',
        }),
        ...mapGetters('user', ['market', 'organizationTypes']),
        ...mapGetters('notification', ['unreadCount']),
        ...mapGetters('platform', ['orderNotAvailableComingSoonMessageActive', 'servicePhone']),
        isUserPending() {
            const id = _get(this.$store.state, 'user.info.user.id', null);

            return this.currentUserId !== id;
        },
        injectLanguageMismatchModal() {
            return !!sessionStorage.getItem('loginLocale');
        },
        displayComingSoonMessage() {
            return this.$can('haveClientOrders') && this.orderNotAvailableComingSoonMessageActive;
        },
        displayOrderingNotAvailableMessage() {
            return !this.$can('createAnyQuoteInCheckout') && this.$can('accessCheckout');
        },
    },
    methods: {
        openResourceCenter(event) {
            event.stopPropagation();
            userflow.toggleResourceCenter();
        },
    },
};
</script>
