export const CONSTRUCTION_PROJECT_LIST_ROUTE = 'ConstructionProject_List';
export const CONSTRUCTION_PROJECT_CREATE_ROUTE = 'ConstructionProject_Create';
export const CONSTRUCTION_PROJECT_DETAILS_ROUTE = 'ConstructionProject_Details';
export const CONSTRUCTION_PROJECT_ORDER_ROUTE = 'ConstructionProject_Order';
export const CONSTRUCTION_PROJECT_POSITIONS_ROUTE = 'ConstructionProject_Positions';
export const CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE = 'ConstructionProject_PositionDetails';
export const CONSTRUCTION_PROJECT_LOCATIONS_ROUTE = 'ConstructionProject_Locations';
export const CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE = 'ConstructionProject_Documents';
export const CONSTRUCTION_PROJECT_ADMIN_CREATE_ROUTE = 'ConstructionProject_AdminCreate';
export const CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE = 'ConstructionProject_AdminEdit';
export const CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE = 'ConstructionProject_Setting_Edit';
export const CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE = 'ConstructionProjects_Settings';
export const CONSTRUCTION_PROJECT_CLIENT_GENERIC_POSITION_DETAILS_ROUTE = 'ConstructionProject_GenericPositionDetails';
export const DAYS_LEFT_NOTIFICATION_THRESHOLD = 7;
