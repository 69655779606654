import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import { Tab } from '@headlessui/react';
import { Button } from '@schuettflix/react-components';
import { PlusIcon } from '@schuettflix/icons-react';
import { useAbility } from '@/reactBridge/useAbility';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { VueRouterLink } from '@/reactBridge/VueRouterLink';
import { StarIcon } from '../components/icons/StarIcon';
import { ConstructionProjectsIcon } from '../components/icons/ConstructionProjectsIcon';
import { SearchIcon } from '../components/icons/SearchIcon';
import { SearchHeader } from '../components/search/SearchHeader';
import { ConstructionProjectSearch } from '../components/search/ConstructionProjectSearch';
import { ConstructionProjectsList } from '../components/ConstructionProjectList';
import { useSearchInput } from '../components/search/useSearchInput';
import { Header } from '../components/Header';
import { CONSTRUCTION_PROJECT_CREATE_ROUTE, CONSTRUCTION_PROJECT_LIST_ROUTE } from '../constants';
import { constructionProjectListQuery } from '../queries';
import { useRouteQuery } from '@/reactBridge/useRouteQuery';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
import { ConstructionProjectListDesktop } from '@/constructionProjects/components/ConstructionProjectListDesktop';
import { useScreenName } from '@schuettflix/analytics-react';
const EmptyConstructionProjectsList = ({ description }) => {
    return (<div className="mb-4 rounded border border-dashed border-disabled p-6 text-center">
            <ConstructionProjectsIcon className="inline-block"/>
            <p className="font-copy-md mt-4 text-disabled">{description}</p>
        </div>);
};
const EmptyFavoriteProjectsList = () => {
    const { t } = useTranslation();
    return (<div className="flex h-[120px] flex-col items-center justify-center border border-dashed border-light-gray text-center">
            <StarIcon className="stroke-icon" size={24}/>
            <p className="font-copy-md-strong mb-1 mt-2">
                {t('pages.constructionProject.favoriteProjects.emptyState.title')}
            </p>
            <p className="font-copy-sm">{t('pages.constructionProject.favoriteProjects.emptyState.description')}</p>
        </div>);
};
const ActiveConstructionProjectList = () => {
    const { t } = useTranslation();
    const canManageConstructionProject = useAbility('createConstructionProject');
    const query = useQuery({
        ...constructionProjectListQuery,
        staleTime: Infinity,
        select: response => response.constructionProjects.filter(project => project.status === ConstructionProjectStatus.Active),
    });
    const [showFavoritableHint] = useState(!query?.data?.some(project => project.isFavorite) || false);
    if (!query.data) {
        return null;
    }
    if (query.data.length === 0) {
        return (<>
                <EmptyConstructionProjectsList description={t('pages.constructionProject.activeProjects.emptyState.description')}/>
                {canManageConstructionProject && (<VueRouterLink className="flex h-12 w-full items-center justify-center rounded bg-surface-primary" data-test="create-construction-project-link" to={{ name: `${CONSTRUCTION_PROJECT_LIST_ROUTE}__${CONSTRUCTION_PROJECT_CREATE_ROUTE}` }}>
                        <PlusIcon on="primary" size="xs"/>
                        <span className="font-copy-md-strong ml-2 text-on-primary">
                            {t('pages.constructionProject.activeProjects.emptyState.createButton')}
                        </span>
                    </VueRouterLink>)}
            </>);
    }
    return (<ConstructionProjectsList projects={query.data} emptyState={<EmptyFavoriteProjectsList />} showFavoritableHint={showFavoritableHint}/>);
};
const InactiveConstructionProjectList = () => {
    const { t } = useTranslation();
    const { data: constructionProjects = [] } = useQuery({
        ...constructionProjectListQuery,
        select: response => response.constructionProjects.filter(project => project.status === ConstructionProjectStatus.Inactive),
    });
    if (constructionProjects.length === 0) {
        return (<EmptyConstructionProjectsList description={t('pages.constructionProject.inactiveProjects.emptyState.description')}/>);
    }
    return <ConstructionProjectsList projects={constructionProjects}/>;
};
const ArchivedConstructionProjectList = () => {
    const { t } = useTranslation();
    const { data: constructionProjects = [] } = useQuery({
        ...constructionProjectListQuery,
        select: response => response.constructionProjects.filter(project => project.status === ConstructionProjectStatus.Archived),
    });
    if (constructionProjects.length === 0) {
        return (<EmptyConstructionProjectsList description={t('pages.constructionProject.archivedProjects.emptyState.description')}/>);
    }
    return <ConstructionProjectsList projects={constructionProjects} favoritable={false}/>;
};
const ConstructionProjectsTabs = () => {
    const { vueRouter } = useVueRouter();
    const { activeTab } = useRouteQuery();
    const { t } = useTranslation();
    const canManageConstructionProject = useAbility('createConstructionProject');
    const TABS = [
        {
            id: ConstructionProjectStatus.Active,
            name: t('pages.constructionProject.activeTab.headline'),
            component: ActiveConstructionProjectList,
            showCount: true,
        },
        {
            id: ConstructionProjectStatus.Inactive,
            name: t('pages.constructionProject.inactiveTab.headline'),
            component: InactiveConstructionProjectList,
            showCount: true,
        },
        {
            id: ConstructionProjectStatus.Archived,
            name: t('pages.constructionProject.archivedTab.headline'),
            component: ArchivedConstructionProjectList,
            showCount: false,
        },
    ];
    const activeTabIndex = TABS.findIndex(item => item.id === activeTab);
    const selectedIndex = activeTabIndex > -1 ? activeTabIndex : 0;
    const { data: tabCounts } = useQuery({
        ...constructionProjectListQuery,
        suspense: true,
        select: response => {
            const counts = Object.fromEntries(TABS.map(tab => [tab.id, 0]));
            for (const project of response.constructionProjects) {
                counts[project.status]++;
            }
            return counts;
        },
    });
    return (<Tab.Group selectedIndex={selectedIndex} onChange={index => {
            vueRouter.push({
                path: vueRouter.currentRoute.path,
                query: { activeTab: TABS[index].id },
            });
        }}>
            <Tab.List className="flex h-12 bg-surface px-4">
                {TABS.map(tab => (<Tab key={tab.id} className={({ selected }) => clsx('font-copy-sm box-border h-full p-4 outline-none', selected && 'font-copy-sm-strong border-b-2 border-b-brand-primary')}>
                        {tab.name} {tab.showCount && tabCounts ? `(${tabCounts[tab.id]})` : null}
                    </Tab>))}
            </Tab.List>
            <div className="flex-1 overflow-auto bg px-4 py-6">
                {canManageConstructionProject && (<Button size="sm" className="mb-8 ml-auto hidden rounded lg:flex" label={t('pages.constructionProject.desktopList.createButton')} data-test="construction-project-list-create-button" onClick={() => vueRouter.push({
                name: `${CONSTRUCTION_PROJECT_LIST_ROUTE}__${CONSTRUCTION_PROJECT_CREATE_ROUTE}`,
            })}/>)}
                <Tab.Panels>
                    {TABS.map(({ id, component: Component }) => (<Tab.Panel key={id} className="focus:outline-none">
                            <Component />
                        </Tab.Panel>))}
                </Tab.Panels>
            </div>
        </Tab.Group>);
};
const HeaderActions = ({ onClickSearch }) => {
    const canManageConstructionProject = useAbility('createConstructionProject');
    return (<div className="flex items-center space-x-4">
            <SearchIcon className="cursor-pointer" size="sm" onClick={onClickSearch}/>
            {canManageConstructionProject && (<VueRouterLink to={{ name: `${CONSTRUCTION_PROJECT_LIST_ROUTE}__${CONSTRUCTION_PROJECT_CREATE_ROUTE}` }} data-test="project-create-button">
                    <PlusIcon size="sm"/>
                </VueRouterLink>)}
        </div>);
};
const _ConstructionProjectListPage = () => {
    useScreenName('client-constructionproject-list');
    const { isSearchOpen, searchQuery, setSearchQuery, closeSearch, openSearch } = useSearchInput();
    const { t } = useTranslation();
    const { isGreaterOrEqual } = useBreakpoints();
    return (<>
            {isGreaterOrEqual('lg') ? (<Suspense>
                    <ConstructionProjectListDesktop />
                </Suspense>) : (<div className="flex h-full flex-col">
                    {isSearchOpen ? (<SearchHeader searchQuery={searchQuery} onChangeSearchQuery={setSearchQuery} onClickCancel={closeSearch}/>) : (<Header desktopTitle={t('pages.constructionProject.projects.headline')} mobileTitle={t('pages.constructionProject.projects.headline')}>
                            <div>
                                <HeaderActions onClickSearch={openSearch}/>
                            </div>
                        </Header>)}

                    {isSearchOpen ? (<Suspense>
                            <ConstructionProjectSearch searchQuery={searchQuery}/>
                        </Suspense>) : (<Suspense>
                            <ConstructionProjectsTabs />
                        </Suspense>)}
                </div>)}
        </>);
};
export const ConstructionProjectList = asVueComponent(_ConstructionProjectListPage);
