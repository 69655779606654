import React from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { GenericPositionAdminActions } from './GenericPositionAdminActions';
import { useRoute } from '@/reactBridge/useRoute';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE } from '@/modules/constructionProjects/pages/routes';
import { ConstructionProjectGenericPositionDetailsHeader } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/ConstructionProjectGenericPositionDetailsHeader';
const _GenericPositionAdminDetailsHeader = () => {
    const { vueRouter } = useVueRouter();
    const currentRoute = useRoute();
    return (<ConstructionProjectGenericPositionDetailsHeader onBack={() => vueRouter.push({ name: CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE })}>
            <GenericPositionAdminActions genericPositionId={currentRoute.params.positionId}/>
        </ConstructionProjectGenericPositionDetailsHeader>);
};
export const GenericPositionAdminDetailsHeader = asVueComponent(_GenericPositionAdminDetailsHeader);
