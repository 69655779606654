import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { constructionProjectByIdAsAdminQuery } from '@/constructionProjects/queries';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React from 'react';
import { Button, Tag } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import DocumentsIcon from '@/constructionProjects/components/icons/DocumentsIcon';
import store from '@/store';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { MenuTile } from '@/constructionProjects/components/MenuTile';
import { ProjectsIcon } from '@/constructionProjects/components/icons/ProjectsIcon';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { MaterialIcon } from '@/constructionProjects/components/icons/MaterialIcon';
import { VehicleIcon } from '@/constructionProjects/components/icons/VehicleIcon';
import { useRoute } from '@/reactBridge/useRoute';
import { PeopleSection } from '@/modules/constructionProjects/components/PeopleSection';
import { FooterSection } from '@/modules/constructionProjects/components/FooterSection';
import { useConstructionProjectValidity } from '@/modules/constructionProjects/hooks/useConstructionProjectValidity';
import { CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE, CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_DOCUMENTS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE, } from '@/modules/constructionProjects/pages/routes';
import { projectByIdQuery } from '@/reactBridge/queryClient';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { useAddressFormatter } from '@/utils/useAddressFormatter';
import ProjectFacadeView from '@/models/ProjectFacadeView';
import { FavoriteAdminStar } from '@/modules/constructionProjects/components/FavoriteStar';
import { findRouteName } from '@/pages/routerUtils';
import { WarningSectionAdmin } from '@/modules/constructionProjects/components/WarningSection';
import { SettingsIcon } from '@/constructionProjects/components/icons/SettingsIcon';
import { PdfIcon } from '@/constructionProjects/components/icons/PdfIcon';
import { useLd } from '@/services/LaunchDarkly';
import { useAdminConstructionProjectDetailCounts } from '@/constructionProjects/hooks/useConstructionProjectsCounts';
function DetailsSection({ constructionProject }) {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const { validityRange, daysLeft, showWarning } = useConstructionProjectValidity(constructionProject);
    const { formatLegacyAddress } = useAddressFormatter();
    const isProjectActive = constructionProject.status === ConstructionProjectStatus.Active;
    const handlePositionButtonClicked = async () => {
        store.commit('projectPosition/RESET');
        store.commit('constructionProject/setConstructionProjectId', constructionProject.id);
        store.commit('constructionProject/setEntryPoint', {
            name: findRouteName(CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE),
            params: { id: constructionProject.id },
        });
        await vueRouter.push({ name: findRouteName(`${CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE}__checkout-project`) });
    };
    return (<section className="space-y-6">
            <div className="card min-h-[200px] rounded p-6 shadow-flat">
                <div className="flex items-start justify-between">
                    <span className="font-headline-md-strong text">{constructionProject.name}</span>
                    <FavoriteAdminStar activeClass="fill-icon-accent" className="flex-shrink-0 -translate-y-2 translate-x-2"/>
                </div>
                <div className="font-copy-md grid h-[80px] grid-cols-[16px_minmax(0,_1fr)] items-start gap-2 pb-6 pt-2 leading-4">
                    {constructionProject.description && (<>
                            <DocumentsIcon size="xs"/>
                            <p>{constructionProject.description}</p>
                        </>)}
                    <MapMarkerIcon />
                    <p>{formatLegacyAddress(constructionProject)}</p>
                </div>
                <div className="flex gap-12">
                    <div className="space-y-2">
                        <p className="font-copy-sm text-subdued">
                            {t('pages.constructionProject.detailsPage.costField.title')}
                        </p>
                        <p className="font-copy-md-strong text">{constructionProject.costCenter || '-'}</p>
                    </div>
                    <div className="space-y-2">
                        <p className="font-copy-sm text-subdued">
                            {t('pages.constructionProject.detailsPage.validityField.title')}
                        </p>
                        <p className="font-copy-md-strong text">
                            <span>{validityRange}</span>
                            {showWarning && (<Tag className="ml-2" type="warning" label={t('pages.constructionProject.projectCard.expirationWarning', {
                count: daysLeft,
            })}/>)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-6">
                <Button data-test="construction-project-admin-details-page-position-button" label={t('pages.constructionProject.details.button.addPosition')} size="sm" className="rounded" onClick={handlePositionButtonClicked} disabled={!isProjectActive}/>
            </div>
        </section>);
}
function LinksSection({ constructionProject }) {
    const route = useRoute();
    const { t } = useTranslation();
    const isShowProjectDocumentListButtonEnabled = useLd('show-project-document-list-button');
    const { counts } = useAdminConstructionProjectDetailCounts(constructionProject.id, { suspense: true });
    const parentRoute = {
        name: route.name,
        params: route.params,
        backTitle: constructionProject.name,
    };
    return (<div className="grid grid-cols-[repeat(auto-fit,_minmax(368px,_1fr))]">
            <MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-positions-link" to={{
            name: findRouteName(CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE),
            params: { id: constructionProject.id },
        }} icon={ProjectsIcon} label={t('pages.constructionProject.details.links.positionsLabel')} description={counts &&
            t('pages.constructionProject.details.links.positionsDescription', {
                count: counts.positionCount,
            })}/>

            <MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-admin-details-page-orders-link" to={{
            name: 'order-management__order-list',
            query: {
                parentRoute: JSON.stringify(parentRoute),
                order: assembleQueryFilter({
                    constructionProject: constructionProject.id,
                    status: ['in_progress', 'new'],
                }),
            },
        }} icon={MaterialIcon} label={t('pages.constructionProject.details.links.ordersLabel')} description={counts &&
            t('pages.constructionProject.details.links.ordersDescription', {
                count: counts.orderCount,
            })}/>

            <MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-admin-details-page-transports-link" to={{
            name: 'order-management__transport-hub',
            query: {
                parentRoute: JSON.stringify(parentRoute),
                transport: assembleQueryFilter({
                    constructionProject: constructionProject.id,
                }),
            },
        }} icon={VehicleIcon} label={t('pages.constructionProject.details.links.transportsLabel')} description={counts &&
            t('pages.constructionProject.details.links.transportsDescription', {
                count: counts.transportCount,
            })}/>

            {isShowProjectDocumentListButtonEnabled && (<MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-documents-link" to={{ name: CONSTRUCTION_PROJECT_ADMIN_DOCUMENTS_ROUTE, params: { id: constructionProject.id } }} icon={PdfIcon} label={t('pages.constructionProject.details.links.documentsLabel')}/>)}

            <MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-project-settings-link" to={{
            name: findRouteName(`${CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE}`),
            params: { id: constructionProject.id },
        }} icon={() => <SettingsIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.details.button.projectSettings.title')} description={t('pages.constructionProject.details.button.projectSettings.subtitle')}/>
        </div>);
}
function CustomerInfo() {
    const { t } = useTranslation();
    const { projectId } = useConstructionProjectRouteParams();
    const { data: project } = useQuery({
        ...projectByIdQuery(projectId),
        enabled: !!projectId,
        select: response => ProjectFacadeView.create(response),
    });
    const { formatLegacyAddress } = useAddressFormatter();
    if (!project) {
        return <div />;
    }
    return (<div className="flex flex-col gap-2 overflow-hidden">
            <p className="font-copy-sm text-subdued">{t('pages.project.view.info.clientLabel')}</p>
            <p className="font-copy-md-strong text">{project.client.name}</p>
            <p className="font-copy-sm overflow-hidden overflow-ellipsis whitespace-nowrap">
                {formatLegacyAddress(project.client.billingAddress)}
            </p>
        </div>);
}
export const ConstructionProjectAdminDetailsDesktop = ({ className, }) => {
    const { projectUuid } = useConstructionProjectRouteParams();
    const { data: constructionProject } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectUuid),
        suspense: true,
    });
    if (!constructionProject) {
        return null;
    }
    return (<div className={clsx('flex flex-grow flex-col gap-12 overflow-auto p-6', className)}>
            <div className="grid grid-cols-[auto_1fr] gap-12">
                <CustomerInfo />
                <PeopleSection constructionProject={constructionProject}/>
            </div>
            <DetailsSection constructionProject={constructionProject}/>
            <WarningSectionAdmin constructionProject={constructionProject}/>
            <LinksSection constructionProject={constructionProject}/>
            <FooterSection projectCode={constructionProject.projectCode}/>
        </div>);
};
