/**
 * Defines to which entity a (document) number belongs to.
 *
 * @see https://github.com/schuettflix/backend/blob/main/src/DocumentNumber/Enum/DocumentType.php
 */
export var NumberType;
(function (NumberType) {
    NumberType[NumberType["UNKNOWN"] = 0] = "UNKNOWN";
    NumberType[NumberType["ACCOUNTING_DOCUMENT"] = 1] = "ACCOUNTING_DOCUMENT";
    NumberType[NumberType["ORDER"] = 2] = "ORDER";
    NumberType[NumberType["PROJECT"] = 3] = "PROJECT";
    NumberType[NumberType["JOB"] = 4] = "JOB";
})(NumberType || (NumberType = {}));
const regularExpressions = new Map([
    [NumberType.ACCOUNTING_DOCUMENT, /^[A-Z]{2}[1234567]\d{9}$/],
    [NumberType.ORDER, /^[A-Z]{2}\d{9}$/],
    [NumberType.PROJECT, /^PJ\d{2}-\d{5}$/],
    [NumberType.JOB, /^[A-Z]{2}\d{9}-\d{3}$/],
]);
/**
 * Tries to find out what kind of entity the number belongs to.
 */
export function fromNumberAsString(input) {
    for (const [type, regex] of regularExpressions) {
        if (regex.test(input)) {
            return type;
        }
    }
    return NumberType.UNKNOWN;
}
