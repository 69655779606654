import { useQuery } from '@tanstack/react-query';
import { httpClient } from '@/constructionProjects/api/client/http-client';
import { constructionProjectKeys } from '@/constructionProjects/queries';
import { useEffect, useState } from 'react';
function useCounts({ constructionProjectIds, adminMode = false, queryOptions }) {
    const queryParams = constructionProjectIds
        ?.reduce((acc, id) => {
        acc.append('constructionProjectIds', id);
        return acc;
    }, new URLSearchParams())
        .toString();
    return useQuery({
        queryKey: [...constructionProjectKeys.namespace, ...(adminMode ? ['admin'] : []), 'counts', queryParams],
        queryFn: async function useCountsQueryFn() {
            if (!constructionProjectIds || constructionProjectIds.length === 0) {
                return undefined;
            }
            let endpoint = `/v1/construction-projects/counts?${queryParams}`;
            if (adminMode) {
                endpoint = `/admin${endpoint}`;
            }
            const response = await httpClient.get(endpoint);
            return response.data;
        },
        enabled: !!constructionProjectIds,
        ...queryOptions,
    });
}
function useAdminConstructionProjects(queryParams) {
    const { page, size, status, searchTerm, responsibleEmployeeId, organizationList } = queryParams;
    return useQuery({
        queryKey: constructionProjectKeys.adminConstructionProjects({
            page,
            size,
            status,
            searchTerm,
            responsibleEmployeeId,
            organizationList,
        }),
        queryFn: async function useDataQueryFn() {
            const response = await httpClient.get('/admin/v1/construction-projects', {
                params: {
                    page,
                    size,
                    status,
                    searchTerm,
                    responsibleEmployeeId,
                    ...(organizationList?.length ? { organizationList: organizationList?.join(',') } : {}),
                },
            });
            return response.data;
        },
        structuralSharing: false,
    });
}
export function useAdminConstructionProjectsWithCounts(queryParams) {
    const [constructionProjectData, setConstructionProjectData] = useState();
    const [constructionProjectIds, setConstructionProjectIds] = useState();
    const { data, isLoading: isLoadingData } = useAdminConstructionProjects(queryParams);
    const { data: counts, isLoading: isLoadingCounts } = useCounts({ constructionProjectIds, adminMode: true });
    useEffect(() => {
        if (data) {
            setConstructionProjectIds(data.constructionProjects.map(({ id }) => id));
            // NOTE: strip `projectPositionInfo` from `constructionProjects` because it's deprecated.
            // It's deprecated because the counts on account for legacy entities.
            setConstructionProjectData(() => {
                const { constructionProjects, ...rest } = data;
                return {
                    ...rest,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    constructionProjects: constructionProjects.map(({ projectPositionInfo, ...rest }) => ({ ...rest })),
                };
            });
        }
    }, [data]);
    useEffect(() => {
        if (constructionProjectData && counts) {
            // NOTE: inject `counts` into `constructionProjects`
            setConstructionProjectData(() => {
                const { constructionProjects, ...rest } = constructionProjectData;
                return {
                    ...rest,
                    constructionProjects: constructionProjects.map(({ id, ...rest }) => ({
                        ...rest,
                        id,
                        counts: counts[id],
                    })),
                };
            });
        }
    }, [constructionProjectData, counts]);
    return {
        data: constructionProjectData,
        isLoading: isLoadingData || isLoadingCounts,
    };
}
function createConstructionProjectDetailCountsHook(adminMode) {
    return function useConstructionProjectDetailCounts(constructionProjectId, queryOptions) {
        const { data } = useCounts({ constructionProjectIds: [constructionProjectId], adminMode, queryOptions });
        const [counts, setCounts] = useState();
        useEffect(() => {
            if (data) {
                // NOTE: Destructure and calculate positionCount differently here in case of changes
                const { transportCount, orderCount, activePositionCount: positionCount } = data[constructionProjectId];
                setCounts({ transportCount, orderCount, positionCount });
            }
        }, [data, constructionProjectId, setCounts]);
        return { counts };
    };
}
export const useAdminConstructionProjectDetailCounts = createConstructionProjectDetailCountsHook(true);
export const useConstructionProjectDetailCounts = createConstructionProjectDetailCountsHook(false);
