import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from '@/reactBridge/useRoute';
import { useConstructionProjectValidity } from '@/modules/constructionProjects/hooks/useConstructionProjectValidity';
import { Link, Tag } from '@schuettflix/react-components';
import { OrgInfo } from './components/OrgInfo';
import { MinusIcon, PlusIcon } from '@schuettflix/icons-react';
import { GenericPositionProducts } from '@/modules/constructionProjects/components/GenericPositionProducts/GenericPositionProducts';
import { useGenericPositionDetailsById } from './hooks/useGenericPositionDetailsById';
export function DetailsSection({ position, buttonBarSlot, pricesSlot }) {
    const { t } = useTranslation();
    const [showFullNote, setShowFullNote] = React.useState(false);
    const productGroup = position.positionProductGroups[0];
    const { validityRange, daysLeft, showWarning } = useConstructionProjectValidity({
        validFrom: position.validFrom ?? '',
        validTo: position.validTo ?? '',
    });
    if (!productGroup) {
        return null;
    }
    const positionNote = position.note?.length
        ? {
            fullNote: position.note,
            shortNote: position.note.length > 220 ? `${position.note.slice(0, 220)}...` : position.note,
            hasMore: position.note.length > 220,
        }
        : undefined;
    return (<section className="space-y-6">
            <div className="card rounded p-4 shadow-flat md:p-6">
                <div className="mb-6 flex flex-col items-start justify-between gap-2 md:flex-row md:gap-0">
                    <div className="flex flex-col gap-1">
                        <p className="font-copy-sm text-subdued">{t('pages.genericPosition.details.validityField')}</p>
                        <div className="flex flex-row-reverse gap-4 md:flex-row">
                            <p className="font-headline-lg text">{validityRange}</p>
                            {showWarning && (<Tag type="warning" label={t('pages.genericPosition.details.expirationWarning', {
                count: daysLeft,
            })}/>)}
                        </div>
                    </div>
                </div>

                <div className="lg:w-1/2">
                    <GenericPositionProducts products={productGroup.products} productGroupType={productGroup.type} numberToShow={3}/>
                </div>

                {pricesSlot}

                {positionNote && (<div className="mt-8 flex max-w-screen-lg flex-col gap-2">
                        <h4 className="font-copy-md-strong">{t('pages.genericPosition.details.note')}</h4>
                        <p className="font-copy-md">{showFullNote ? positionNote.fullNote : positionNote.shortNote}</p>
                        {positionNote?.hasMore && (<Link className="font-bold" prefix={showFullNote ? <MinusIcon /> : <PlusIcon />} label={showFullNote
                    ? t('pages.genericPosition.details.showLess')
                    : t('pages.genericPosition.details.showMore')} onClick={() => setShowFullNote(!showFullNote)}/>)}
                    </div>)}
            </div>
            {buttonBarSlot}
        </section>);
}
export const GenericPositionDetails = ({ className, actionsSlot, detailsSlot, }) => {
    const { t } = useTranslation();
    const currentRoute = useRoute();
    const { data } = useGenericPositionDetailsById(currentRoute.params.positionId);
    if (!data) {
        return null;
    }
    const { position } = data;
    return (<div className={clsx('flex flex-grow flex-col gap-12', className)}>
            <div className="flex flex-col-reverse justify-between gap-2 md:flex-row md:items-center md:gap-12">
                <OrgInfo productGroupType={position.positionProductGroups[0]?.type}/>
                <div className="flex items-center gap-2">
                    <Tag className="self-end md:self-auto" type={position.status === 'CLOSED' ? 'error' : position.active ? 'positive' : 'default'} label={t(`pages.genericPosition.details.tags.${position.status === 'CLOSED' ? 'CLOSED' : position.active ? 'ACTIVE' : 'INACTIVE'}`)}/>
                    <div className="hidden lg:flex">{actionsSlot && actionsSlot(position)}</div>
                </div>
            </div>
            {detailsSlot(position)}
        </div>);
};
