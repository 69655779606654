import { httpClient } from '@/constructionProjects/api/client/http-client';
import { useI18n } from 'vue-i18n-composable';
import { reactive } from 'vue';
import LogService from '@schuettflix/util-log';
const Log = new LogService('modules/constructionProjects/composables/usePositionTabLabels');
// NOTE: This function integrates with the `construction-project-public-api-service` as single source of truth for position counts.
// It replaces an implementation with many indirections:
// - The `FilterableListing` class internally fetches counts for legacy positions from the monolith alongside position data.
// - When calling the constructor you had to pass a callback function to the `restrictionLabelRenderer` option.
// - The callback function's purpose was to return a label including a count (fn type: (restrictionKey: string, count: number) => string).
// - This callback function gets called whenever the `restrictionsLabelSet` get method on `FilterableListing` is called.
// - The get method then return a simple map of type `Record<string, string>`. The `Labels` type above reflects this type in its definition specific for positions.
// - When this function is used alongside a `FilterableListing` instance for data, make sue to pass the `restrictionCountsEnabled: false` to the constructor to prevent the class internal legacy count retrieval.
export function usePositionTabLabels({ constructionProjectId, adminMode = false, }) {
    const { t } = useI18n();
    const openLabel = t(`pages.project.position.tab.label.open`);
    const inactiveLabel = t(`pages.project.position.tab.label.inactive`);
    const closedLabel = t(`pages.project.position.tab.label.closed`);
    const raw = {
        open: openLabel.toString(),
        inactive: inactiveLabel.toString(),
        closed: closedLabel.toString(),
    };
    const labels = reactive(raw);
    httpClient
        .get(`${adminMode ? '/admin' : ''}/v1/construction-projects/counts?constructionProjectIds=${constructionProjectId}`)
        .then(({ data }) => {
        labels.open = `${labels.open} (${data[constructionProjectId].activePositionCount})`;
        labels.inactive = `${labels.inactive} (${data[constructionProjectId].inactivePositionCount})`;
        labels.closed = `${labels.closed} (${data[constructionProjectId].closedPositionCount})`;
    })
        .catch(Log.error);
    return labels;
}
