import OrderListPage from '@/pages/Order/OrderListPage';
import OrderViewPage from '@/pages/Order/OrderViewPageV2';
import CheckoutSummaryPage from '@/pages/Checkout/SummaryPage/SummaryPage';
import SupplierViewPage from '@/pages/Supplier/SupplierViewPage';
import OrderStartPickupPage from '@/pages/Order/OrderStartPickupPage';
import OrderTransportListPage from '@/pages/Order/OrderTransportListPage';
import GenericOrderDetails from './components/GenericOrderDetails.vue';
import { routerInstance } from '@/utils/instances';
import { findRouteName } from '../routerUtils';
import OrderApi from '@/services/Api/Order';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { getLd } from '@/services/LaunchDarkly';
import Toaster from '@/services/Toaster';
import i18n from '@/i18n';

export default {
    path: 'orders',
    name: 'order-list',
    component: OrderListPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['listOrders'],
    },
    children: [
        {
            include: 'order-view',
        },
        {
            path: 'quote/:quoteId',
            name: 'order-quote-view',
            component: CheckoutSummaryPage,
            props: true,
            meta: {
                next: 'home',
                noHeader: true,
            },
            beforeEnter(to, from, next) {
                if (from && from.name) {
                    localStorage.setItem('checkout-summary-check-previous', from.name);
                }
                to.meta.previous = localStorage.getItem('checkout-summary-check-previous');
                next();
            },
        },
        {
            path: ':orderId',
            name: 'generic-order-details',
            component: GenericOrderDetails,
            props: route => ({
                orderId: route.params.orderId,
                onPanelClose: () => {
                    const router = routerInstance.get();
                    const route = router.currentRoute;
                    const name = findRouteName('order-list');
                    router.push({ name, query: route.query });
                },
            }),
            beforeEnter: (_to, _from, next) => {
                const ld = getLd();
                const featureFlag = ld.flags?.['monolith-display-generic-order-details'];
                if (!featureFlag) {
                    Toaster.error(i18n.t('authenticator.errors.forbidden'));
                    next('/');
                }
                next();
            },
        },
    ],
};

export const orderViewRoute = {
    path: 'order/:orderId',
    name: 'order-view',
    component: OrderViewPage,
    props: true,
    beforeEnter(to, _from, next) {
        OrderApi.getOneById(to.params.orderId)
            .then(order => {
                const isGenericOrder = order.lineItemGroups.every(
                    group => group.type === ORDER_LINE_ITEM_GROUP_TYPE.GENERIC_ORDER
                );
                if (isGenericOrder) {
                    next({
                        name: 'order-management__order-list__generic-order-details',
                        params: { orderId: order.genericOrderId },
                    });
                    return;
                }
                next();
            })
            .catch(() => {
                Toaster.error(i18n.t('authenticator.errors.forbidden'));
                next('/');
            });
    },
    children: [
        {
            path: 'transports/:lineItemGroupId?',
            name: 'order-transports',
            component: OrderTransportListPage,
            props: true,
            meta: {
                previous: 'order-view',
            },
            children: [
                {
                    include: 'transport-view',
                },
            ],
        },
        {
            path: 'supplier/:factoryId',
            name: 'order-supplier-view',
            component: SupplierViewPage,
            props: true,
        },
        {
            path: 'pickup/start',
            name: 'order-pickup-start',
            component: OrderStartPickupPage,
            props: true,
            children: [
                {
                    include: 'vehicle-new',
                },
            ],
        },
    ],
};
