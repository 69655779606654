<template>
    <LayoutPage
        class="max-h-screen"
        screen-name="platform-order-list"
        :parent-route="parentRoute"
        :back-title="parentRoute?.backTitle"
    >
        <template #pageTitle>{{ $t('pages.order.platformOrderList.title') }}</template>
        <template #mobileHeader>
            <HeaderBar>
                <template v-if="parentRoute" #left>
                    <HeaderBarItem button @click="goToParent()">
                        <ArrowIcon width="32" height="18" />
                    </HeaderBarItem>
                </template>

                <template #headline>
                    <div>
                        {{ $t('pages.order.platformOrderList.title') }}
                    </div>
                </template>

                <template #right>
                    <HeaderBarItem>
                        <FilterBox
                            v-model="filter"
                            screen-name="platform-order-filter"
                            no-padding
                            :default-filter="defaultFilter"
                            :endpoint="endpoint"
                            :forced-filter="forcedFilter"
                            :ignore-in-count="[
                                'organization',
                                'user',
                                'constructionProject',
                                'excludeGenericOrder',
                                'status',
                            ]"
                            @save="refreshList(false, true)"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <PlatformOrderFilterSet
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="endpoint"
                                    :whitelisted-fields="whitelistedFields"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </HeaderBarItem>

                    <HeaderBarItem>
                        <FilterSort
                            :sort-by="filter.sortBy"
                            :sort-direction="filter.sortDirection"
                            :sort-options="sortOptions"
                            :option-label-renderer="value => $t(`pages.order.platformOrderList.sortings.${value}`)"
                            @sort-by-update="filter.sortBy = $event"
                            @sort-direction-update="filter.sortDirection = $event"
                            @updated-sorting="refreshList(false, true)"
                        />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <template #default>
            <SfPanelManager class="max-h-[calc(100vh-120px)]">
                <template #default="{ panelControl }">
                    <div class="max-h-full overflow-y-auto overflow-x-visible">
                        <RoundedTabNavigation :value="activeTab" :tabs="orderViewTabs" @input="updateTabNavigation" />

                        <FilterBox
                            v-model="filter"
                            screen-name="platform-order-filter"
                            :forced-filter="forcedFilter"
                            :default-filter="filter"
                            :endpoint="endpoint"
                            inline-mode
                            class="mb-8 bg-white shadow"
                            @update="refreshList(false, true)"
                        >
                            <template #default="filterScope">
                                <div v-if="$root.isDesktop" class="flex w-full flex-wrap gap-4">
                                    <TextField
                                        v-model="filterScope.filter.search"
                                        :label="$t('pages.order.platformOrderList.filter.searchLabel')"
                                        class="grow basis-3/5"
                                    />
                                    <SelectBox
                                        v-model="filterScope.filter.type"
                                        :label="$t('pages.order.platformFilter.selectOrderType')"
                                        class="shrink-0 flex-grow"
                                    >
                                        <option value>
                                            {{ $t('pages.order.platformOrderList.filter.type.options.default') }}
                                        </option>
                                        <option v-for="option in typeOptions" :key="option" :value="option">
                                            {{ $t(`pages.order.platformOrderList.filter.type.options.${option}`) }}
                                        </option>
                                    </SelectBox>
                                </div>

                                <FilterSortPagination
                                    :result="orders"
                                    :filter="filterScope.filter"
                                    :sort-options="sortOptions"
                                    :option-label-renderer="
                                        value => $t(`pages.order.platformOrderList.sortings.${value}`)
                                    "
                                    :hide-sort="!$root.isDesktop"
                                    show-refresh
                                    :loading="isLoading"
                                    @refresh="refreshList(false, true)"
                                    @pageNumberUpdated="pageNumberUpdated"
                                >
                                    <FilterBox
                                        v-if="$root.isDesktop"
                                        v-model="filter"
                                        screen-name="platform-order-filter"
                                        no-padding
                                        :default-filter="defaultFilter"
                                        :forced-filter="forcedFilter"
                                        :endpoint="endpoint"
                                        :ignore-in-count="[
                                            'organization',
                                            'user',
                                            'constructionProject',
                                            'excludeGenericOrder',
                                            'status',
                                        ]"
                                        :button-label="$t('pages.transportHub.filter.moreFilterButtonLabel')"
                                        @save="refreshList(false, true)"
                                    >
                                        <template #default="{ filter: filterFromSlot, updateFilter }">
                                            <PlatformOrderFilterSet
                                                :filter-scope="{ filter: filterFromSlot }"
                                                :endpoint="endpoint"
                                                :whitelisted-fields="whitelistedFields"
                                                @updateFilter="updateFilter"
                                            />
                                        </template>
                                    </FilterBox>
                                </FilterSortPagination>
                            </template>
                        </FilterBox>

                        <LoadingSpinner v-if="!orders" block dark />

                        <transition v-if="orders" name="fade" mode="out-in">
                            <div v-if="orders.count > 0">
                                <Card
                                    v-for="item in orders.items"
                                    :key="item.id"
                                    class="my-4"
                                    :spaceless-x="$root.isDesktop"
                                >
                                    <OrderItemBlock :order-view="item" @click="selectOrder(item)" />
                                </Card>

                                <Card spaceless>
                                    <Pagination :result="orders" align-right @pageNumberUpdated="pageNumberUpdated" />
                                </Card>
                            </div>

                            <Hint v-else center>
                                {{ $t('pages.order.orderList.orders.noResults') }}
                            </Hint>
                        </transition>

                        <PanelControlContextProvider
                            v-if="isGenericOrderDetailsSubPageOpen"
                            :panel-control="panelControl"
                        >
                            <router-view />
                        </PanelControlContextProvider>
                    </div>
                </template>
            </SfPanelManager>
        </template>

        <template #subpages>
            <Flyout route="order-view" size="small" no-header />
            <Flyout route="order-quote-view" size="small" no-header />
        </template>
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import { ensurePHPTimestamp } from '@/services/utils/date';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import OrderApi from '@/services/Api/Order';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { EVENT_ORDER_UPDATED } from '@/constants/events';
import OrderItemBlockView from '@/components/List/OrderItemBlockView';

import Card from '@/components/Layout/Card.vue';
import FilterBox from '@/components/Filter/FilterBox.vue';
import FilterSort from '@/components/Filter/FilterSort.vue';
import FilterSortPagination from '@/components/Filter/FilterSortPagination.vue';
import Flyout from '@/components/Layout/Flyout.vue';
import HeaderBar from '@/components/Header/HeaderBar.vue';
import HeaderBarItem from '@/components/Header/HeaderBarItem.vue';
import Hint from '@/components/Typography/Hint.vue';
import LayoutPage from '@/components/Layout/Page.v2.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import OrderItemBlock from '@/components/List/OrderItemBlock.vue';
import Pagination from '@/components/Pagination.vue';
import PlatformOrderFilterSet from './PlatformOrderFilterSet.vue';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation.vue';
import SelectBox from '@/components/Form/SelectBox.v2.vue';
import TextField from '@/components/Form/TextField.v2.vue';
import { defineComponent } from 'vue';
import { useFeatureFlag } from '@/services/FeatureFlags/useFeatureFlags';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { SfPanelManager } from '@schuettflix/vue-components';
import { useLd } from '@/services/LaunchDarkly';
import PanelControlContextProvider from '@/pages/Order/Context/PanelControlContext.vue';
import { useRoute } from 'vue-router/composables';
import ArrowIcon from '@/assets/icons/regular/arrow.svg';

const DEFAULT_PAGE_COUNT = 25;

export default defineComponent({
    name: 'PlatformOrderList',
    components: {
        ArrowIcon,
        Card,
        FilterBox,
        FilterSort,
        FilterSortPagination,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        LoadingSpinner,
        OrderItemBlock,
        Pagination,
        PlatformOrderFilterSet,
        RoundedTabNavigation,
        SelectBox,
        TextField,
        SfPanelManager,
        PanelControlContextProvider,
    },
    mixins: [persistentFiltersMixin, eventHubMixin],
    setup() {
        // The parent route is used to redirect back to the screen that initially linked to the transport list.
        // Otherwise, on mobile, when we add the transport list from another page the user has no way to get back.
        // We use a query param to pass the parent route to the transport list so that we can link back even we the
        // use has opened subpages like transport details in the mean time.
        let parentRoute;

        const route = useRoute();
        try {
            parentRoute = JSON.parse(route.query.parentRoute);
        } catch {
            // noop parent route stays undefined
        }

        const { isEnabled: isDisposalFeatureEnabled } = useFeatureFlag('disposal_project_order');
        const isGenericOrderDetailsEnabled = useLd('monolith-display-generic-order-details');

        return {
            isDisposalFeatureEnabled,
            isGenericOrderDetailsEnabled,
            parentRoute,
        };
    },
    data() {
        return {
            orders: null,
            cancelSource: null,
            isLoading: false,
            endpoint: OrderApi,
            filter: this.assembleFilter('order', {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
            }),
            forcedFilter: this.assembleFilter('orderForced', {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
                status: ['new'],
            }),
            defaultFilter: {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
            },
            activePredefinedFilter: 'new',
            predefinedFilters: {
                new: {
                    perPage: DEFAULT_PAGE_COUNT,
                    status: ['new'],
                },
                in_progress: {
                    perPage: DEFAULT_PAGE_COUNT,
                    status: ['in_progress'],
                },
                closed: {
                    perPage: DEFAULT_PAGE_COUNT,
                    status: ['closed'],
                },
                canceled: {
                    perPage: DEFAULT_PAGE_COUNT,
                    status: ['canceled'],
                },
            },
        };
    },
    computed: {
        isGenericOrderDetailsSubPageOpen() {
            return this.isGenericOrderDetailsEnabled && this.$route.name.includes('generic-order-details');
        },
        activeTab() {
            if (this.forcedFilter.status) {
                return this.forcedFilter.status[0];
            }

            return 'new';
        },
        orderViewTabs() {
            const tabs = {};

            Object.keys(this.predefinedFilters).forEach(predefinedFilter => {
                tabs[predefinedFilter] = this.$t(`pages.order.platformOrderList.facet.${predefinedFilter}`);
            });

            return tabs;
        },
        sortOptions() {
            return _intersection(OrderApi.supportedSorts, [
                'created',
                'orderNumber',
                'client',
                'supplier',
                'invoiceStatus',
            ]);
        },
        typeOptions() {
            return _intersection(Object.keys(_get(OrderApi, 'supportedFilters.type.options', {})), [
                ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY,
                ORDER_LINE_ITEM_GROUP_TYPE.PICKUP,
                ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT,
                ORDER_LINE_ITEM_GROUP_TYPE.GENERIC_ORDER,
                ...(this.isDisposalFeatureEnabled ? ['transport-disposal'] : []),
            ]);
        },
        whitelistedFields() {
            const mobile = this.$root.isDesktop ? [] : ['search', 'type'];

            return [
                ...mobile,
                'orderNumberSearch',
                'startCreated',
                'endCreated',
                'transportNumberSearch',
                'deliveryNoteNumberSearch',
                'clientSearch',
                'supplierSearch',
                'carrierSearch',
                'destinationSearch',
                'startRetailPrice',
                'endRetailPrice',
                'invoiceStatus',
            ];
        },
    },
    watch: {
        $route() {
            this.checkActiveFilters();
            this.filter = this.assembleFilter('order', this.defaultFilter);
            this.forcedFilter = this.assembleFilter('orderForced', this.predefinedFilters[this.activePredefinedFilter]);
            this.refreshList(true);
        },
    },
    created() {
        this.refreshList(true);

        this.subscribe(EVENT_ORDER_UPDATED, () => {
            this.refreshList();
        });
    },
    mounted() {
        this.checkActiveFilters();
    },
    methods: {
        ensurePHPTimestamp,

        pageNumberUpdated(number) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$eventHub.$emit('page.actions.scroll-top');
            this.filter.page = number;
            this.refreshList();
        },

        updateTabNavigation(tab) {
            // Move the construction project filter over to other tabs
            // when coming from the construction project page
            const constructionProjectFilter = this.filter.constructionProject
                ? this.assembleQueryFilter({ constructionProject: this.filter.constructionProject })
                : {};

            this.$router
                .push({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        order: constructionProjectFilter,
                        orderForced: this.assembleQueryFilter(this.predefinedFilters[tab]),
                    },
                })
                .catch(() => {});
        },

        checkActiveFilters() {
            this.activePredefinedFilter = 'new';

            Object.keys(this.predefinedFilters).forEach(key => {
                if (this.isQueryFilterActive('orderForced', this.predefinedFilters[key])) {
                    this.activePredefinedFilter = key;
                }
            });
        },

        async refreshList(isInitial = false, resetPagination = false) {
            this.isLoading = true;

            // check if we have to reset the pagination
            if (resetPagination) {
                this.filter.page = 1;
            }

            // persist filter
            this.persistFilter('order', this.filter, this.defaultFilter);

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = OrderApi.createCancelTokenSource();

            try {
                const result = await OrderApi.filter(this.filter, null, null, this.cancelSource, this.forcedFilter);
                result.transform(item => OrderItemBlockView.create(item));
                this.orders = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        selectOrder(order) {
            if (order.isGenericOrder)
                return (
                    this.isGenericOrderDetailsEnabled &&
                    this.$router
                        .push({
                            name: this.$root.findRouteName('generic-order-details'),
                            params: { orderId: order.genericOrderId },
                            query: this.$route.query,
                        })
                        .catch(() => {})
                );

            this.$router
                .push({
                    name: this.$root.findRouteName('order-list__order-view'),
                    params: { orderId: order.id },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        orderType(order) {
            const orderType = _get(order, 'lineItemGroups.0.type', null);
            if (!orderType) return '';

            switch (orderType) {
                case ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY:
                    return this.$t('pages.order.platformOrderList.orderType.delivery');
                case ORDER_LINE_ITEM_GROUP_TYPE.PICKUP:
                    return this.$t('pages.order.platformOrderList.orderType.pickup');
                case ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT:
                    return this.$t('pages.order.platformOrderList.orderType.shipment');
                default:
                    return '';
            }
        },

        openSortBox() {
            this.$refs.sortingBoxFlyout.$emit('openSortingBox');
        },

        showDate(currentTimestamp, previousIndex, itemList) {
            if (previousIndex >= 0) {
                const current = this.$d(currentTimestamp * 1000, 'short');
                const previous = this.$d(itemList[previousIndex].created * 1000, 'short');

                return current !== previous;
            }

            return true;
        },

        showTransports(orderId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-transports'),
                    params: {
                        ...this.$route.params,
                        orderId,
                    },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        goToParent() {
            if (this.parentRoute) {
                this.$router.push(this.parentRoute).catch(() => {});
            }
        },
    },
});
</script>
