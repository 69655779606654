import { projectByIdQuery } from '@/reactBridge/queryClient';
import ProjectFacadeView from '@/models/ProjectFacadeView';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRoute } from '@/reactBridge/useRoute';
import { useGenericPositionDetailsById } from '../hooks/useGenericPositionDetailsById';
export function OrgInfo({ productGroupType }) {
    const { t } = useTranslation();
    const { projectId } = useConstructionProjectRouteParams();
    const { data: project } = useQuery({
        ...projectByIdQuery(projectId ?? -1),
        enabled: !!projectId,
        select: response => ProjectFacadeView.create(response),
    });
    const currentRoute = useRoute();
    const { data } = useGenericPositionDetailsById(currentRoute.params.positionId);
    if (!project || !data) {
        return <div />;
    }
    const { organizations } = data;
    return (<div className="flex flex-col gap-6 md:flex-row md:flex-wrap lg:gap-12">
            <div className="flex flex-col gap-1 overflow-hidden lg:gap-2">
                <p className="font-copy-sm text-subdued">{t('pages.project.view.info.clientLabel')}</p>
                <p className="font-copy-md-strong text">{project.client.name}</p>
            </div>
            {!!organizations?.length &&
            organizations.map(org => (<div key={org.id + JSON.stringify(org.productCategories)} className="flex flex-col gap-1 overflow-hidden lg:gap-2">
                        <p className="font-copy-sm text-subdued">
                            {org.productCategories
                    .map(category => (productGroupType === 'FRANCO' || productGroupType === 'MERCHANT') &&
                    category === 'MATERIAL'
                    ? t('pages.genericPosition.orgInfo.MATERIAL_TRANSPORT')
                    : t(`pages.genericPosition.orgInfo.${category}`))
                    .join(', ')}
                        </p>
                        <p className="font-copy-md-strong text">{org.name}</p>
                    </div>))}
        </div>);
}
