import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useOrganization';
import { sortByProductCategory } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/utils/sortByProductCategory';
import { PRODUCT_GROUP_TYPE_SCHEMA, } from '@/modules/constructionProjects/api/position-service-interfaces';
import { Transactions } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/components/Transactions';
import { getFrancoOverviewTransactions } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/utils/getFrancoOverviewTransactions';
function CustomerTransactions(props) {
    const { t } = useTranslation();
    const { data: organization } = useOrganization(props.organizationId);
    return (<div>
            <div className="font-copy-sm mb-1">{t('pages.genericPosition.customer')}</div>
            <div className="font-headline-md-strong mb-4 overflow-hidden text-ellipsis whitespace-nowrap">
                {organization?.name}
            </div>
            <Transactions {...props}/>
        </div>);
}
export function CustomerProductsSection({ organizations, productGroupType }) {
    const organizationsWithCustomerTransactions = organizations
        .map(org => {
        const customerTransactions = org.transactions.filter(transaction => transaction.functionalRoles.includes('CUSTOMER'));
        const isFranco = productGroupType === PRODUCT_GROUP_TYPE_SCHEMA.enum.FRANCO;
        return {
            ...org,
            transactions: (isFranco
                ? getFrancoOverviewTransactions(customerTransactions)
                : customerTransactions).sort(sortByProductCategory),
        };
    })
        .filter(org => org.transactions.length > 0);
    return (<div>
            {organizationsWithCustomerTransactions.map(customerTransactions => (<CustomerTransactions key={customerTransactions.orgId} organizationId={customerTransactions.orgId} financeSettings={customerTransactions.financeSettings} transactions={customerTransactions.transactions}/>))}
        </div>);
}
