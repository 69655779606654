import { PRODUCT_CATEGORY_SCHEMA } from '@/modules/constructionProjects/api/position-service-interfaces';
export function getFrancoOverviewTransactions(transactions) {
    const transportTransaction = transactions.find(transaction => transaction.productCategory === 'TRANSPORT');
    const materialTransaction = transactions.find(transaction => transaction.productCategory === 'MATERIAL');
    if (!transportTransaction || !materialTransaction) {
        return transactions;
    }
    const combinedTransaction = {
        ...materialTransaction,
        productCategory: PRODUCT_CATEGORY_SCHEMA.enum.MATERIAL_TRANSPORT,
        unitPriceAmount: materialTransaction.unitPriceAmount + transportTransaction.unitPriceAmount,
    };
    const restOfTransactions = transactions.filter(transaction => transaction.productCategory !== 'MATERIAL' && transaction.productCategory !== 'TRANSPORT');
    return [combinedTransaction, ...restOfTransactions];
}
