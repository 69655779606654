import { Card } from '../../Components/Card';
import { Section } from '../../Components/Section';
export function BaseInformationPreview() {
    return (<Card title="1. Stammdaten" onEdit={() => console.log('clicked')}>
            <div className="font-copy-sm grid grid-cols-2 text-subdued">
                <div>
                    <p>Musterunternehmen GmbH</p>
                    <p>Musterstraße 123n</p>
                    <p>12343 Munsterstadt</p>
                    <p>Deutschland</p>
                    <p>Nordrhein-Westfalen</p>
                </div>
                <div>
                    <p>DE124356789</p>
                </div>
            </div>
            <hr className="my-6 border-divider"/>
            <Section title="Kontakt">
                <p>kontakt@musterunternehmen.de</p>
                <p>+49 151 234 567 89</p>
            </Section>
        </Card>);
}
