import apiService from '@/services/Api/ApiService';
import { useQuery } from '@tanstack/react-query';
export function useTaxClasses() {
    return useQuery({
        queryKey: ['tax-classes'],
        queryFn: () => apiService
            .get(`/finance/v1/platform/tax-classes`)
            .then(res => res.data.taxClasses),
        suspense: true,
    });
}
