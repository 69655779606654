import Vue from 'vue';
import Router from 'vue-router';

import _pick from 'lodash/pick';
import _findIndex from 'lodash/findIndex';
import Toaster from '@/services/Toaster';
import { decorateRoute, resolveIncludes } from '@/services/utils/router';
import store from '@/store';
import { EventBus } from '@/services/EventBus';
import {
    CONTEXT_CARRIER,
    CONTEXT_CARRIER_DRIVER,
    CONTEXT_CLIENT,
    CONTEXT_PLATFORM,
    CONTEXT_SUPPLIER,
} from '@/constants/context';

import HomeRoute from '@/pages/Home';
import LoginRoute from '@/pages/Login';
import NotificationsRoute from '@/pages/Notifications';
import OrderRoute, { orderViewRoute } from '@/pages/Order';
import OrderQuantityRoute from '@/pages/OrderQuantity';
import ProductManagementRoute from '@/pages/ProductManagement';
import SupplierProductsRoute from '@/pages/SupplierProducts';
import TransportRoute, { transportViewRoute } from '@/pages/Transport';
import TransportHubRoute from '@/pages/TransportHub';
import OrganizationRoute from '@/pages/Organization';
import InvoiceRoute from '@/pages/Invoice';
import ManualBillingUI from '@/pages/ManualBillingUI';
import AccountingUI from '@/pages/AccountingUI';
import CreditNoteRoute from '@/pages/CreditNote';
import DocumentsRoute from '@/pages/Documents';
import OrganizationAccounting from '@/pages/OrganizationAccounting';
import ReportingRoute from '@/pages/Reporting';
import LegalRoute from '@/pages/Legal';
import LogbookRoute from '@/pages/Logbook';
import PlatformToolsRoute from '@/pages/PlatformTools';
import CheckoutRoute from '@/pages/Checkout';
import UserManagementRoutes from '@/pages/User';
import SettingsRoute from '@/pages/Settings';
import CourtListRoute from '@/pages/CourtList';
import ActiveTripsRoute from '@/pages/ActiveTrips';
import ToolFlixRoute from '@/pages/ToolFlix';
import {
    projectPositionListRoute,
    projectPositionAnalysisRoute,
    constructionProjectAdminRoutes,
} from '@/pages/Project';
import PaymentTermsRoute from '@/pages/PaymentTermsSelection';

import { constructionProjectRoutes } from '@/constructionProjects/pages/routes';

import AppRenderer from '@/pages/components/AppRenderer';

import PlaygroundRoute from '@/pages/Playground';
import ChildRenderer from '@/components/Router/ChildRenderer';
import { createVehicleFormRoute } from '@/pages/Vehicle';
import { ld, setupLaunchDarkly } from '@/services/LaunchDarkly';
import { prefetchQueries } from '@/reactBridge/queryClient';
import { routerInstance, routesInstance } from '@/utils/instances';
import { analyticsService } from '@/services/Analytics/new';
import { getConditionalRedirect, getOrderRedirect } from './routerUtils';
import OrganizationForm from '@/pages/Magic/index.ts';

Vue.use(Router);

function eventBusRoutes(ebRoutes) {
    const generatedRoutes = [];

    Object.keys(ebRoutes).forEach(path => {
        const name = ebRoutes[path];

        generatedRoutes.push({
            path,
            name,
            beforeEnter: (to, from, next) => {
                next({ ...from });

                EventBus.$emit(`page.${name}`, true);
                // this is required for a new tab
                setTimeout(() => {
                    EventBus.$emit(`page.${name}`, true);
                }, 1000);
            },
        });
    });

    return generatedRoutes;
}

const routes = resolveIncludes(
    [projectPositionAnalysisRoute, orderViewRoute, transportViewRoute, createVehicleFormRoute],
    [
        LoginRoute,
        LegalRoute,
        ...eventBusRoutes({
            '/terms-of-service': 'termsOfService',
            '/terms-of-use': 'termsOfUse',
            '/contact': 'platformContact',
            '/privacy-policy': 'privacyPolicy',
            '/imprint': 'imprint',
        }),
        {
            path: '',
            name: 'root',
            component: AppRenderer,
            meta: {
                requiresAuth: true,
            },
            async beforeEnter(to, from, next) {
                prefetchQueries();
                setupLaunchDarkly();
                await ld.client.waitUntilReady();
                const nextRoute = getConditionalRedirect(to);
                next(nextRoute);
            },
            children: [
                {
                    path: '/orders/:orderId',
                    name: 'orders',
                    redirect: to => {
                        const { orderId } = to.params;
                        const redirect = getOrderRedirect(orderId);
                        return redirect;
                    },
                },
                {
                    path: '/order',
                    name: 'order',
                    component: ChildRenderer,
                    children: [
                        decorateRoute(OrderRoute),
                        decorateRoute(TransportRoute, {
                            meta: {
                                context: CONTEXT_CLIENT,
                            },
                        }),
                        decorateRoute(CheckoutRoute),
                    ],
                    meta: {
                        context: CONTEXT_CLIENT,
                    },
                },

                ...constructionProjectAdminRoutes,

                {
                    path: '/order-management',
                    name: 'order-management',
                    component: ChildRenderer,
                    children: [
                        decorateRoute(OrderRoute),
                        decorateRoute(CheckoutRoute),
                        decorateRoute(TransportHubRoute, {
                            meta: {
                                requiredAbilities: ['havePlatformTransportManagement'],
                            },
                        }),
                    ],
                    meta: {
                        context: CONTEXT_PLATFORM,
                    },
                },

                {
                    path: '/management',
                    name: 'management',
                    component: ChildRenderer,
                    children: [
                        ...OrganizationRoute,
                        UserManagementRoutes,
                        ProductManagementRoute,
                        PlatformToolsRoute,
                        ToolFlixRoute,
                        DocumentsRoute,
                        OrganizationAccounting,
                        SettingsRoute,
                        ReportingRoute,
                        ...SupplierProductsRoute,
                    ],
                },

                {
                    path: '/materials',
                    name: 'materials',
                    component: ChildRenderer,
                    children: [
                        OrderQuantityRoute,
                        decorateRoute(projectPositionListRoute, {
                            meta: {
                                requiredAbilities: ['listSupplierProjectPositions'],
                            },
                        }),
                        decorateRoute(TransportRoute, {
                            meta: {
                                context: CONTEXT_SUPPLIER,
                                requiredAbilities: ['listSupplierTransports'],
                            },
                        }),
                        CourtListRoute,
                    ],
                    meta: {
                        context: CONTEXT_SUPPLIER,
                    },
                },

                ...OrganizationForm,

                {
                    path: '/accounting',
                    name: 'accounting',
                    component: ChildRenderer,
                    children: [
                        InvoiceRoute,
                        ...CreditNoteRoute,
                        PaymentTermsRoute,
                        ...ManualBillingUI,
                        ...AccountingUI,
                    ],
                    meta: {
                        context: CONTEXT_PLATFORM,
                        // Temporary used to track screens without names.
                        // Will be removed once all screens have names.
                        section: 'platform-accountingsection',
                    },
                },
                {
                    path: '/logistics',
                    name: 'logistics',
                    component: ChildRenderer,
                    children: [
                        decorateRoute(projectPositionListRoute, {
                            meta: {
                                context: CONTEXT_CARRIER,
                                requiredAbilities: ['listCarrierProjectPositions'],
                            },
                        }),
                        decorateRoute(TransportHubRoute, {
                            meta: {
                                context: CONTEXT_CARRIER,
                                requiredAbilities: ['haveTransportHub'],
                            },
                        }),
                        decorateRoute(TransportRoute, {
                            meta: {
                                context: CONTEXT_CARRIER,
                                requiredAbilities: ['haveCarrierTransports'],
                            },
                        }),
                        decorateRoute(ActiveTripsRoute, {
                            meta: {
                                context: CONTEXT_CARRIER_DRIVER,
                                requiredAbilities: ['listActiveTrips'],
                            },
                        }),
                        decorateRoute(ActiveTripsRoute, {
                            path: 'pickup-active-trips',
                            name: 'pickup-active-trips',
                            meta: {
                                context: CONTEXT_CLIENT,
                                requiredAbilities: ['listActivePickupTrips'],
                            },
                        }),
                        decorateRoute(LogbookRoute, {
                            meta: {
                                context: CONTEXT_CARRIER_DRIVER,
                            },
                        }),
                    ],
                },

                ...NotificationsRoute,

                ...constructionProjectRoutes,

                OrderQuantityRoute,
                TransportRoute,
                TransportHubRoute,
                PlaygroundRoute,
                HomeRoute,
                {
                    path: '*',
                    redirect: '/dashboard',
                },
            ],
        },
        {
            path: '*',
            name: 'dashboard',
            redirect: '/dashboard',
        },
    ]
);

const router = new Router({
    base: process.env.BASE_URL,
    routes,
});

routesInstance.set(routes);
routerInstance.set(router);

function trackSections(to, from) {
    const fromSection = from.matched.find(r => r.meta.section)?.meta.section;
    const toSection = to.matched.find(r => r.meta.section)?.meta.section;

    if (fromSection) {
        analyticsService.leaveScreen(fromSection);
    }

    if (toSection) {
        analyticsService.enterScreen(toSection);
    }
}

router.beforeEach((to, from, next) => {
    // Remove stored validation errors
    store.dispatch('validation/cleanup');

    // Temporary tracking entire sections under while not all screen have names
    // yet. This will be removed once all screens have names.
    trackSections(to, from, next);

    // route resolver
    if (to.name.match('%')) {
        // current routes name placeholder
        let name = to.name.replace('%current%', from.name);

        // replace parent placeholder
        if (to.name.match('%parent%')) {
            const parentRoute = getParentRouteByName(from.name);

            if (parentRoute) {
                name = name.replace('%parent%', parentRoute.name || parentRoute.meta.name || from.name);
            }
        }

        const newTo = { ...to, name };
        return router.push(newTo);
    }

    // Check required Authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters['user/isLoggedIn']) {
            return next({
                name: 'login',
                query: {
                    redirect: to.fullPath,
                },
            });
        }
    }

    // check if user has accepted termsOfUse
    if (
        store.getters['user/isLoggedIn'] &&
        (!store.getters['user/isTermsOfUseAccepted'] || !store.getters['user/isTermsOfServiceAccepted']) &&
        to.name !== 'legal-check'
    ) {
        return next({
            name: 'legal-check',
            query: {
                redirect: to.fullPath,
            },
        });
    }

    const requiredAbilities = [
        ...new Set(
            to.matched
                .map(record =>
                    Array.isArray(record.meta.requiredAbilities)
                        ? record.meta.requiredAbilities
                        : [record.meta.requiredAbilities]
                )
                .flat()
                .filter(n => !!n)
        ),
    ];

    if (requiredAbilities && requiredAbilities.length) {
        if (!requiredAbilities.every(check => store.getters['abilities/can'](check))) {
            Toaster.info('You are not allowed to access this page.');
            return next({ name: from.name });
        }
    }

    return next();
});

function getParentRouteByName(routeName = null) {
    const matched = router.currentRoute.matched;

    if (matched.length < 2) {
        return false;
    }

    routeName = routeName !== null ? routeName : router.currentRoute.name;

    const routeIndex = _findIndex(matched, { name: routeName });
    const currentRoute = matched[routeIndex];

    let parentRoute = (currentRoute && currentRoute.parent) || null;
    let iterations = 0;

    while (parentRoute && ['ChildRenderer', 'CheckoutRenderer'].includes(parentRoute.components.default.name)) {
        parentRoute = parentRoute.parent;
        iterations++;
        if (iterations > 200) {
            throw new Error('Iterations limit exceeded for getParentRouteByName');
        }
    }

    return parentRoute;
}

export function routeBack(routeName = null) {
    const parentRoute = getParentRouteByName(routeName);

    // found parent route, call it
    if (parentRoute === null) {
        return false;
    }

    const params = router.currentRoute.params;
    const query = router.currentRoute.query;
    let newParams = {};

    // only set a subset of params for the parent
    let whitelistedProps = parentRoute.path ? parentRoute.path.match(/:[^\/]+/g) : null;
    if (whitelistedProps !== null) {
        whitelistedProps = whitelistedProps.map(v => v.replace(':', ''));
        newParams = _pick(params, whitelistedProps);
    }

    return router.push({
        name: parentRoute.name,
        params: newParams,
        query,
    });
}

export { findRouteName } from './routerUtils';

export default router;
