import { asVueComponent } from '@/reactBridge/asVueComponent';
import { BaseInformationPreview } from './BaseInformation/Preview';
import { AccountingPreview } from './Accounting/Preview';
import { PermissionsPreview } from './Permissions/Preview';
export function _OrganizationForm() {
    return (<div className="flex h-full flex-col gap-2 overflow-auto py-16">
            <BaseInformationPreview />
            <AccountingPreview />
            <PermissionsPreview />
        </div>);
}
export const OrganizationForm = asVueComponent(_OrganizationForm);
