import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@/constructionProjects/components/Header';
import { useRoute } from '@/reactBridge/useRoute';
import { useGenericPositionDetailsById } from './hooks/useGenericPositionDetailsById';
export const ConstructionProjectGenericPositionDetailsHeader = ({ children, onBack }) => {
    const currentRoute = useRoute();
    const { t } = useTranslation();
    const { data } = useGenericPositionDetailsById(currentRoute.params.positionId);
    if (!data) {
        return null;
    }
    const { position } = data;
    return (<Header desktopTitle={position.positionNumber} mobileTitle={position.positionNumber} navigationTitle={t('pages.genericPosition.details.header.title')} onBack={onBack}>
            {children}
        </Header>);
};
