import React from 'react';
import { PdfIcon } from '@/constructionProjects/components/icons/PdfIcon';
import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { MaterialIcon } from '@/constructionProjects/components/icons/MaterialIcon';
import { ProjectsIcon } from '@/constructionProjects/components/icons/ProjectsIcon';
import { SettingsIcon } from '@/constructionProjects/components/icons/SettingsIcon';
import { TagIcon } from '@/constructionProjects/components/icons/TagIcon';
import { VehicleIcon } from '@/constructionProjects/components/icons/VehicleIcon';
import { MenuTile } from '@/constructionProjects/components/MenuTile';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@schuettflix/react-components';
import store from '@/store';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { assembleQueryFilter } from '@/plugins/composables/usePersistentFilters';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import clsx from 'clsx';
import { constructionProjectByIdAsAdminQuery } from '@/constructionProjects/queries';
import { useRoute } from '@/reactBridge/useRoute';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { FooterSection } from '@/modules/constructionProjects/components/FooterSection';
import { useAddressFormatter } from '@/utils/useAddressFormatter';
import { CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE, CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_DOCUMENTS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE, } from '@/modules/constructionProjects/pages/routes';
import { findRouteName } from '@/pages/routerUtils';
import { WarningSectionAdmin } from '@/modules/constructionProjects/components/WarningSection';
import { useLd } from '@/services/LaunchDarkly';
import { useAdminConstructionProjectDetailCounts } from '@/constructionProjects/hooks/useConstructionProjectsCounts';
function DetailsHeader({ constructionProject }) {
    const { vueRouter } = useVueRouter();
    const { t } = useTranslation();
    const { formatLegacyAddress } = useAddressFormatter();
    const isProjectActive = constructionProject.status === ConstructionProjectStatus.Active;
    const handlePositionButtonClicked = async () => {
        store.commit('projectPosition/RESET');
        store.commit('constructionProject/setConstructionProjectId', constructionProject.id);
        store.commit('constructionProject/setEntryPoint', {
            name: CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE,
            params: { id: constructionProject.id },
        });
        await vueRouter.push({ name: findRouteName(`${CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE}__checkout-project`) });
    };
    const costCenter = constructionProject.costCenter;
    const name = constructionProject.name;
    return (<section className="bg-surface p-4">
            <p className="font-copy-lg-strong mb-2">{name}</p>
            <div className="font-copy-md grid grid-cols-[16px_minmax(0,_1fr)] items-start gap-2 leading-4">
                <MapMarkerIcon />
                <p>{formatLegacyAddress(constructionProject)}</p>

                {costCenter && (<>
                        <TagIcon />
                        <p>{costCenter}</p>
                    </>)}
            </div>
            {isProjectActive && (<Button data-test="construction-project-admin-details-page-position-button" label={t('pages.constructionProject.details.button.addPosition')} size="sm" className="mt-6 w-full rounded" onClick={handlePositionButtonClicked} disabled={!isProjectActive}/>)}
        </section>);
}
function LinksSection({ constructionProject }) {
    const { t } = useTranslation();
    const route = useRoute();
    const isShowProjectDocumentListButtonEnabled = useLd('show-project-document-list-button');
    const { counts } = useAdminConstructionProjectDetailCounts(constructionProject.id, { suspense: true });
    const parentRoute = {
        name: route.name,
        params: route.params,
        backTitle: constructionProject.name,
    };
    return (<>
            <section className="m-4 rounded bg-surface shadow-low">
                <ul className="divide-y divide-light-gray-400">
                    <MenuTile dataTest="construction-project-details-page-positions-link" to={{
            name: findRouteName(CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE),
            params: { id: constructionProject.id },
        }} icon={ProjectsIcon} label={t('pages.constructionProject.details.links.positionsLabel')} description={counts &&
            t('pages.constructionProject.details.links.positionsDescription', {
                count: counts.positionCount,
            })}/>
                    <MenuTile dataTest="construction-project-details-page-orders-link" to={{
            name: 'order-management__order-list',
            query: {
                parentRoute: JSON.stringify(parentRoute),
                order: assembleQueryFilter({
                    constructionProject: constructionProject.id,
                    status: ['in_progress', 'new'],
                }),
            },
        }} icon={MaterialIcon} label={t('pages.constructionProject.details.links.ordersLabel')} description={counts &&
            t('pages.constructionProject.details.links.ordersDescription', {
                count: counts.orderCount,
            })}/>
                    <MenuTile dataTest="construction-project-details-page-transports-link" to={{
            name: 'order-management__transport-hub',
            query: {
                parentRoute: JSON.stringify(parentRoute),
                transport: assembleQueryFilter({
                    constructionProject: constructionProject.id,
                }),
            },
        }} icon={VehicleIcon} label={t('pages.constructionProject.details.links.transportsLabel')} description={counts &&
            t('pages.constructionProject.details.links.transportsDescription', {
                count: counts.transportCount,
            })}/>

                    {isShowProjectDocumentListButtonEnabled && (<MenuTile dataTest="construction-project-details-page-documents-link" to={{
                name: CONSTRUCTION_PROJECT_ADMIN_DOCUMENTS_ROUTE,
                params: { id: constructionProject.id },
            }} icon={PdfIcon} label={t('pages.constructionProject.details.links.documentsLabel')}/>)}
                </ul>
            </section>
            <section className="m-4 mt-2 rounded bg-surface shadow-low">
                <ul className="divide-y divide-light-gray-400">
                    <MenuTile dataTest="construction-project-details-page-project-settings-link" to={{
            name: findRouteName(`${CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE}`),
            params: { id: constructionProject.id },
        }} appendPath icon={() => <SettingsIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.details.button.projectSettings.title')} description={t('pages.constructionProject.details.button.projectSettings.subtitle')}/>
                </ul>
            </section>
        </>);
}
export const ConstructionProjectAdminDetailsMobile = ({ className, }) => {
    const { projectUuid } = useConstructionProjectRouteParams();
    const { data: constructionProject } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectUuid),
        suspense: true,
    });
    if (!constructionProject) {
        return null;
    }
    return (<div className={clsx('flex flex-grow flex-col overflow-auto', className)}>
            <DetailsHeader constructionProject={constructionProject}/>
            <div className="p-4">
                <WarningSectionAdmin constructionProject={constructionProject}/>
            </div>
            <LinksSection constructionProject={constructionProject}/>
            <FooterSection projectCode={constructionProject.projectCode}/>
        </div>);
};
