<template>
    <div v-if="currentTimestamp" class="business-hours">
        <SfAccordion v-if="isAccordion" class="-mx-4">
            <SfAccordionItem id="opening-hours">
                <template #preheader>
                    <SfTag :type="isOpen ? 'positive' : 'error'">
                        {{ $t(`components.businessHours.${isOpen ? 'open' : 'closed'}`) }}
                    </SfTag>
                </template>

                <template #title>
                    {{ info }}
                </template>

                <OpeningTimes v-if="hasOpeningTimes" class="-mt-4" :opening-times="openingTimes" today-top />
            </SfAccordionItem>
        </SfAccordion>

        <template v-else>
            <div class="flex items-center gap-4 py-6">
                <SfTag :type="isOpen ? 'positive' : 'error'">
                    {{ $t(`components.businessHours.${isOpen ? 'open' : 'closed'}`) }}
                </SfTag>
                <span class="font-headline-md">{{ info }}</span>
            </div>
            <OpeningTimes v-if="hasOpeningTimes" class="mb-6" :opening-times="openingTimes" today-top />
        </template>
    </div>
</template>

<script>
import { ensureJSTimestamp } from '@/services/utils/date';
import formatDate from 'date-fns/format';
import isToday from 'date-fns/is_today';
import isTomorrow from 'date-fns/is_tomorrow';

import OpeningTimes from './OpeningTimes';

import { SfAccordion, SfAccordionItem, SfTag } from '@schuettflix/vue-components';

export default {
    name: 'BusinessHours',
    components: {
        OpeningTimes,

        // components
        SfAccordion,
        SfAccordionItem,
        SfTag,
    },
    props: {
        openingTimestamp: {
            type: Number,
            required: true,
        },
        closingTimestamp: {
            type: Number,
            required: true,
        },
        openingTimes: {
            type: Array,
            default: () => [],
        },
        accordion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentTimestamp: null,
        };
    },
    computed: {
        isValidTimestamps() {
            return (
                this.openingTimestamp != null &&
                this.closingTimestamp != null &&
                !isNaN(Number(this.openingTimestamp)) &&
                !isNaN(Number(this.closingTimestamp))
            );
        },
        isOpen() {
            return this.currentTimestamp > ensureJSTimestamp(this.openingTimestamp);
        },
        isAccordion() {
            return this.accordion && this.hasOpeningTimes;
        },
        hasOpeningTimes() {
            return !!this.openingTimes.length;
        },
        info() {
            if (!this.isValidTimestamps) {
                return this.$t('components.businessHours.invalidTimestamps');
            }
            if (this.isOpen) {
                const closingDate = new Date(ensureJSTimestamp(this.closingTimestamp));
                return this.$t('components.businessHours.closingDate', { time: formatDate(closingDate, 'H:mm') });
            }

            const openingDate = new Date(ensureJSTimestamp(this.openingTimestamp));

            if (isToday(openingDate) || isTomorrow(openingDate)) {
                return this.$t('components.businessHours.openingDate', { time: formatDate(openingDate, 'H:mm') });
            }

            return this.$t('components.businessHours.openingDateWithWeekday', {
                time: formatDate(openingDate, 'H:mm'),
                weekday: this.$t(`weekdays.${openingDate.getDay()}`),
            });
        },
    },
    created() {
        this.$interval(this.updateCurrentTimestamp, 10000);
        this.updateCurrentTimestamp();
    },
    methods: {
        updateCurrentTimestamp() {
            this.currentTimestamp = Date.now();
        },
    },
};
</script>
