import { isLdActivated } from '@/services/LaunchDarkly';
import { getNextRoute } from '@/services/utils/router';
import { routerInstance, routesInstance, storeInstance } from '@/utils/instances';
import { omit } from 'lodash';
import { z } from 'zod';
/**
 * Find route name based on partial route name matching
 */
export function findRouteName(partialName, fallback = null) {
    const router = routerInstance.get();
    if (!router)
        return fallback;
    const route = getNextRoute(routesInstance.get(), router.currentRoute.name || '', partialName);
    if (route === null) {
        return fallback;
    }
    return route.name;
}
/**
 * Decodes a base64-encoded string and parses it as JSON.
 *
 * @param encodedString - The base64-encoded string to decode and parse.
 * @returns The parsed JSON object, or undefined if the decoding or parsing fails.
 */
export const decodeBase64Json = (encodedString) => {
    if (!encodedString) {
        return;
    }
    try {
        return JSON.parse(atob(encodedString));
    }
    catch (e) {
        return;
    }
};
/**
 * Schema for conditional redirect query parameter `conditional-redirect`
 */
const ConditionalRedirectSchema = z.object({
    to: z.string(),
    conditions: z.array(z.object({
        featureFlag: z.string(),
        is: z.boolean(),
    })),
});
/**
 * Returns a conditional redirect object based on the provided query parameters.
 *
 * @param to - The query object containing the 'conditional-redirect' parameter.
 * @returns The conditional redirect object if all conditions are met, otherwise undefined.
 */
export const getConditionalRedirect = (to) => {
    const decodedConditionalRedirect = decodeBase64Json(to.query['conditional-redirect']);
    const conditionalRedirectParseResult = ConditionalRedirectSchema.safeParse(decodedConditionalRedirect);
    if (!conditionalRedirectParseResult.success)
        return undefined;
    const { to: redirectTo, conditions } = conditionalRedirectParseResult.data;
    const allConditionsMet = conditions.every(({ featureFlag, is }) => isLdActivated(featureFlag) === is);
    return allConditionsMet
        ? {
            path: redirectTo,
            replace: true,
            query: omit(to.query, 'conditional-redirect'),
        }
        : undefined;
};
const isUuid = (id) => z.string().uuid().safeParse(id).success;
export const getOrderRedirect = (orderId) => {
    const platformRouteOrderNumber = (orderId) => `/order-management/orders/order/${orderId}`;
    const platformRouteOrderUuid = (orderId) => `/order-management/orders/${orderId}`;
    const clientRouteOrderNumber = (orderId) => `/order/orders/order/${orderId}`;
    const isPlatformAdmin = storeInstance.get()?.getters['user/isPlatformAdministrator'];
    if (isPlatformAdmin) {
        if (isUuid(orderId)) {
            return platformRouteOrderUuid(orderId);
        }
        return platformRouteOrderNumber(orderId);
    }
    return clientRouteOrderNumber(orderId);
};
