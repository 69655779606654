import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { Header } from '@/constructionProjects/components/Header';
import { Tab } from '@headlessui/react';
import { Button, Combobox, Link, LoadingSpinner } from '@schuettflix/react-components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, PlusIcon } from '@schuettflix/icons-react';
import { ConstructionProjectAdminCreateForm } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectAdminCreateForm';
import { Flyout } from '@/constructionProjects/components/Flyout';
import { ProjectList } from '@/modules/constructionProjects/components/Listing/ProjectList';
import { useQuery } from '@tanstack/react-query';
import { adminConstructionProjectsMetadataQuery } from '@/constructionProjects/queries';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { SearchHeader } from '@/modules/constructionProjects/components/Listing/SearchHeader';
import { Pagination } from '@/modules/constructionProjects/components/Listing/Pagination';
import { useAdminConstructionProjectsWithCounts } from '@/constructionProjects/hooks/useConstructionProjectsCounts';
const _ConstructionProjectAdminListPage = () => {
    const SEARCH_DEBOUNCE_TIME = 700;
    const { t } = useTranslation();
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    const [delayedSearchTerm, setDelayedSearchTerm] = useState(null);
    const [queryParams, setQueryParams] = useState({
        page: 1,
        size: 25,
        status: null,
        searchTerm: null,
        responsibleEmployeeId: null,
        organizationList: [],
    });
    useEffect(() => {
        const timeout = setTimeout(() => {
            setQueryParams(queryParams => {
                const page = queryParams.searchTerm !== delayedSearchTerm ? 1 : queryParams.page;
                return {
                    ...queryParams,
                    searchTerm: delayedSearchTerm,
                    page,
                };
            });
        }, SEARCH_DEBOUNCE_TIME);
        return () => clearTimeout(timeout);
    }, [delayedSearchTerm]);
    const { data, isLoading: isLoadingConstructionProjects } = useAdminConstructionProjectsWithCounts(queryParams);
    const { data: metadata, isLoading: isLoadingMetadata } = useQuery({
        ...adminConstructionProjectsMetadataQuery(),
    });
    const responsibleEmployeeSelectOptions = useMemo(() => metadata?.responsibleEmployee.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
    })), [metadata?.responsibleEmployee]);
    const organizationSelectOptions = useMemo(() => metadata?.organization.map((organization) => ({
        label: organization.name,
        value: organization.id,
    })), [metadata?.organization]);
    const TABS = [
        {
            id: null,
            name: t('pages.projectList.filterBar.tabs.all'),
            showCount: true,
            showStatus: true,
        },
        {
            id: ConstructionProjectStatus.Active,
            name: t('pages.projectList.filterBar.tabs.active'),
            showCount: true,
            showStatus: false,
        },
        {
            id: ConstructionProjectStatus.Inactive,
            name: t('pages.projectList.filterBar.tabs.inactive'),
            showCount: true,
            showStatus: false,
        },
        {
            id: ConstructionProjectStatus.Archived,
            name: t('pages.projectList.filterBar.tabs.archived'),
            showCount: false,
            showStatus: false,
        },
    ];
    const handleClearSearch = () => {
        setDelayedSearchTerm(null);
        setQueryParams(queryParams => ({
            ...queryParams,
            searchTerm: null,
            organizationList: [],
            responsibleEmployeeId: null,
        }));
    };
    const countByStatus = {
        [ConstructionProjectStatus.Active]: metadata?.constructionProjectCount?.active,
        [ConstructionProjectStatus.Inactive]: metadata?.constructionProjectCount?.inactive,
        [ConstructionProjectStatus.Archived]: metadata?.constructionProjectCount?.archived,
    };
    return (<div className="flex h-full min-h-screen flex-col overflow-auto bg">
            <Header desktopTitle={t('pages.constructionProject.projects.headline')} mobileTitle={t('pages.constructionProject.projects.headline')}/>
            <main className="grow pb-6 md:p-6">
                <Tab.Group>
                    <Tab.List className="mb-0 flex h-12 pl-1">
                        {TABS.map(tab => (<Tab key={tab.id} className={({ selected }) => clsx('font-copy-sm box-border h-full p-4 outline-none', selected && 'font-copy-sm-strong rounded-t bg-surface')} onClick={() => setQueryParams(queryParams => ({ ...queryParams, status: tab.id, page: 1 }))}>
                                {tab.name}&nbsp;
                                {tab.showCount && tab.id && countByStatus[tab.id] ? `(${countByStatus[tab.id]})` : null}
                            </Tab>))}
                    </Tab.List>
                    <div className="mb-4 flex flex-col gap-4 bg-surface p-6 md:gap-6">
                        {/* SEARCH */}
                        <div className="h-12 grow">
                            <SearchHeader searchQuery={delayedSearchTerm || ''} onChangeSearchQuery={setDelayedSearchTerm}/>
                        </div>

                        {isLoadingMetadata && (<div className="flex w-full items-center justify-center">
                                <LoadingSpinner block/>
                            </div>)}

                        {/* FILTERS */}
                        {metadata && !isLoadingMetadata && (<div className="flex flex-col items-end justify-end gap-6 xl:flex-row xl:items-center xl:justify-between">
                                <div className="flex w-full flex-col gap-4 md:max-w-[1024px] md:flex-row md:gap-6 md:self-start">
                                    {organizationSelectOptions && (<Combobox variant="sm" className="w-full" label={t('pages.projectList.filterBar.customerCompany.label')} value={queryParams.organizationList} multiple options={organizationSelectOptions} onChange={(value) => setQueryParams(queryParams => ({
                    ...queryParams,
                    organizationList: value,
                    page: 1,
                }))} data-test="organization-filter"/>)}
                                    {responsibleEmployeeSelectOptions && (<Combobox variant="sm" className="w-full" label={t('pages.projectList.filterBar.responsibleEmployee.label')} options={responsibleEmployeeSelectOptions} value={queryParams.responsibleEmployeeId} onChange={(value) => setQueryParams(queryParams => ({
                    ...queryParams,
                    responsibleEmployeeId: value,
                    page: 1,
                }))} data-test="responsible-employee-filter"/>)}
                                </div>
                                <Link label={t('pages.projectList.filterBar.clearButton.label')} className="whitespace-nowrap py-4 md:py-0" strong prefix={<DeleteIcon size="xs"/>} onClick={handleClearSearch}/>
                            </div>)}
                    </div>

                    <div className="flex flex-col-reverse gap-4 px-6 pb-6 md:flex-row md:justify-between md:px-0">
                        {data && (<Pagination pageSize={queryParams.size} pageNumber={queryParams.page} totalPages={data.totalPages} totalItems={data.totalElements} onChangePageNumber={(value) => setQueryParams(queryParams => {
                return { ...queryParams, page: value };
            })}/>)}
                        <div></div>
                        <Button size="sm" className="rounded" label={t('pages.projectList.createButton.label')} variant="primary" leadingIcon={props => <PlusIcon {...props} on="primary"/>} onClick={() => setFlyoutOpen(true)} data-test="create-project-button"/>
                    </div>
                    {isLoadingConstructionProjects && (<div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner block spaced/>
                        </div>)}

                    {!isLoadingConstructionProjects && (<Tab.Panels className="mt-2 px-6 md:px-0">
                            {TABS.map(tab => (<Tab.Panel key={tab.id}>
                                    <ProjectList items={data?.constructionProjects || []} showStatus={tab.showStatus} searchTerm={delayedSearchTerm || ''} status={queryParams.status} showFavoritesPlaceholder={queryParams.page === 1}/>
                                </Tab.Panel>))}
                        </Tab.Panels>)}
                </Tab.Group>
            </main>
            <Flyout show={flyoutOpen} screenName="platform-constructionproject-create" onClose={() => setFlyoutOpen(false)} headerTitle={t('pages.projectList.createForm.header.title')} body={<Suspense>
                        <ConstructionProjectAdminCreateForm onSubmit={() => {
                setFlyoutOpen(false);
            }} onCancel={() => setFlyoutOpen(false)}/>
                    </Suspense>}/>
        </div>);
};
export const ConstructionProjectAdminListPage = asVueComponent(_ConstructionProjectAdminListPage);
