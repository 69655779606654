import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useOrganization';
import { ProductSummary } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/components/ProductSummary';
import { PRODUCT_CATEGORY_LABELS } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/utils/ProductCategoryLabels';
import { sortByProductCategory } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/utils/sortByProductCategory';
function SupplierTransaction(props) {
    const { t } = useTranslation();
    const { data: organization } = useOrganization(props.organizationId);
    return (<div>
            {props.transactions.sort(sortByProductCategory).map(transaction => (<div key={transaction.productId}>
                    <div className="font-copy-sm mb-1">{t(PRODUCT_CATEGORY_LABELS[transaction.productCategory])}</div>
                    <div className="font-headline-md-strong mb-4 overflow-hidden text-ellipsis whitespace-nowrap">
                        {organization?.name}
                    </div>

                    <ProductSummary financeSettings={props.financeSettings} transaction={transaction}/>
                </div>))}
        </div>);
}
function getNonCustomerTransactionsByProductCategories(organizations, productCategories) {
    return organizations
        .map(org => {
        return {
            ...org,
            transactions: org.transactions.filter(transaction => !transaction.functionalRoles.includes('CUSTOMER') &&
                productCategories.includes(transaction.productCategory)),
        };
    })
        .filter(org => org.transactions.length > 0);
}
export function SupplierProductsSection(props) {
    const materials = getNonCustomerTransactionsByProductCategories(props.organizations, [
        'MATERIAL_TRANSPORT',
        'MATERIAL',
    ]);
    const transports = getNonCustomerTransactionsByProductCategories(props.organizations, ['TRANSPORT']);
    const services = getNonCustomerTransactionsByProductCategories(props.organizations, [
        'MATERIAL_SERVICE',
        'TRANSPORT_SERVICE',
    ]);
    return (<>
            {materials.map(material => (<SupplierTransaction key={material.orgId} organizationId={material.orgId} financeSettings={material.financeSettings} transactions={material.transactions}/>))}

            {transports.map(material => (<SupplierTransaction key={material.orgId} organizationId={material.orgId} financeSettings={material.financeSettings} transactions={material.transactions}/>))}

            {services.map(material => (<SupplierTransaction key={material.orgId} organizationId={material.orgId} financeSettings={material.financeSettings} transactions={material.transactions}/>))}
        </>);
}
