import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, Tooltip } from '@schuettflix/react-components';
import { StarFilledIcon } from '@/constructionProjects/components/icons/StarFilledIcon';
import { StarIcon } from '@/constructionProjects/components/icons/StarIcon';
import { DAYS_LEFT_NOTIFICATION_THRESHOLD } from '@/constructionProjects/constants';
import { MaterialIcon } from '@schuettflix/icons-react';
import { updateFavoriteStatusAsAdmin } from '@/constructionProjects/api/projects';
import { ProjectStatusTag } from '@/modules/constructionProjects/components/Listing/ProjectStatusTag';
import { ProjectValidityDate } from '@/modules/constructionProjects/components/Listing/ProjectValidityDate';
import clsx from 'clsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { constructionProjectKeys } from '@/constructionProjects/queries';
import { Highlighter } from '@/pages/Checkout/components/Highlighter';
export const ProjectCard = ({ id, name, organizationInfo, responsibleEmployee, costCenter, isFavorite, validFrom, validTo, status, color, counts, projectCode, onCardClick, showStatus, searchTerm, isLoading, }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const mutation = useMutation(updateFavoriteStatusAsAdmin, {
        onSuccess: () => {
            queryClient.invalidateQueries(constructionProjectKeys.allAdminConstructionProjects());
        },
    });
    const handleUpdateFavoriteStatus = () => {
        mutation.mutate({ constructionProjectId: id, isFavorite: !isFavorite });
    };
    return (<div className="relative mb-4 grid min-h-[100px] w-full cursor-pointer grid-cols-1 gap-4 rounded-lg bg-surface px-4 pb-4 shadow-low md:mb-0 md:grid-cols-[16px_auto] md:gap-2 md:rounded-none md:border-b-[1px] md:px-0 md:py-4 md:first:rounded-t-lg md:last:rounded-b-lg" onClick={() => onCardClick?.()} data-test="project-card">
            {isLoading && (<div className="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-20 md:rounded-none">
                    <LoadingSpinner block/>
                </div>)}
            {/* COLOR BAR */}
            <div className={clsx(color ? 'h-4' : 'h-0', 'w-full rounded-b-lg md:h-full md:w-4 md:rounded-r-lg md:rounded-bl-none')} style={color ? { backgroundColor: color } : {}}>
                &nbsp;
            </div>

            {/* CONTENT */}
            <div className="flex gap-1 md:gap-2">
                <div className="grow pl-2">
                    <div className="flex shrink grow flex-col justify-between gap-2">
                        <div className="flex flex-col gap-2">
                            {/* MOBILE VISIBLE */}
                            <div className="flex items-center justify-between gap-2 pb-2 pr-2 md:hidden">
                                {/* PROJECT CODE MOBILE */}
                                <Tooltip text={t('pages.constructionProject.projectCard.tooltip.projectNumber')} position="bottom" size="sm">
                                    <Highlighter highlight={searchTerm || ''} text={projectCode}/>
                                </Tooltip>

                                {/* STATUS MOBILE */}
                                {showStatus && (<Tooltip text={t('pages.constructionProject.projectCard.tooltip.projectStatus')} position="top" size="sm">
                                        <ProjectStatusTag status={status}/>
                                    </Tooltip>)}
                            </div>
                            {/* /MOBILE VISIBLE */}

                            {/* PROJECT NAME */}
                            <div className="font-copy-md-strong" data-test="project-card-name">
                                <Tooltip text={t('pages.constructionProject.projectCard.tooltip.projectName')} position="bottom" size="sm">
                                    <Highlighter highlight={searchTerm || ''} text={name}/>
                                </Tooltip>
                            </div>

                            {/* ORGANIZATION */}
                            <div className="flex flex-col gap-2 md:flex-row md:items-center">
                                <Tooltip text={t('pages.constructionProject.projectCard.tooltip.customer')} position="bottom" size="sm">
                                    <div className="font-copy-md">{organizationInfo.name}</div>
                                </Tooltip>
                                {costCenter && (<Tooltip text={t('pages.constructionProject.projectCard.tooltip.costCenter')} position="bottom" size="sm">
                                        <span className="font-copy-sm text-subdued">
                                            (<Highlighter highlight={searchTerm || ''} text={costCenter}/>)
                                        </span>
                                    </Tooltip>)}
                            </div>

                            {/* VALIDITY DATE MOBILE */}
                            <div className="md:hidden">
                                <ProjectValidityDate validFrom={validFrom} validTo={validTo} daysThreshold={DAYS_LEFT_NOTIFICATION_THRESHOLD}/>
                            </div>
                        </div>

                        <div className="flex w-full flex-col md:flex-row md:justify-between">
                            {/* OPEN POSITIONS DESKTOP */}
                            <div className="hidden items-center gap-2 pt-2 md:flex">
                                <MaterialIcon size="sm" monochrome className="hidden md:block"/>
                                <div>
                                    {/* NOTE: We could show a spinner here because counts are fetched independent of projects */}
                                    {counts &&
            t('pages.constructionProject.projectCard.openPositions.long', {
                count: counts.activePositionCount,
                total: counts.activePositionCount +
                    counts.inactivePositionCount +
                    counts.closedPositionCount,
            })}
                                </div>
                            </div>

                            {/* OPEN POSITIONS MOBILE */}
                            <div className="font-copy-md text-subdued md:hidden">
                                {/* NOTE: We could show a spinner here because counts are fetched independent of projects */}
                                {counts &&
            t('pages.constructionProject.projectCard.openPositions.long', {
                count: counts.activePositionCount,
                total: counts.totalPositionCount,
            })}
                            </div>

                            {/* RESPONSIBLE EMPLOYEE MOBILE */}
                            <div className="block pt-2 md:hidden">
                                <Tooltip text={t('pages.constructionProject.projectCard.tooltip.responsibleSchuttflixEmployee')} position="bottom" size="sm">
                                    <span className="text-subdued">
                                        {responsibleEmployee.firstName} {responsibleEmployee.lastName}
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>

                {/* DESKTOP VISIBLE */}
                <div className="hidden flex-col items-end justify-start md:flex md:gap-2">
                    <div className="flex items-center justify-end gap-2">
                        {/* VALIDITY DATE DESKTOP */}
                        <div className="pr-4">
                            <ProjectValidityDate validFrom={validFrom} validTo={validTo} daysThreshold={DAYS_LEFT_NOTIFICATION_THRESHOLD}/>
                        </div>

                        {/* PROJECT CODE DESKTOP */}
                        <Tooltip text={t('pages.constructionProject.projectCard.tooltip.projectNumber')} position="bottom" size="sm">
                            <Highlighter highlight={searchTerm || ''} text={projectCode} className="font-numbers-md whitespace-nowrap break-keep text-subdued"/>
                        </Tooltip>

                        {/* STATUS DESKTOP */}
                        {showStatus && (<Tooltip text={t('pages.constructionProject.projectCard.tooltip.projectStatus')} position="top" size="sm">
                                <ProjectStatusTag status={status}/>
                            </Tooltip>)}
                    </div>
                    <div>
                        {/* RESPONSIBLE EMPLOYEE DESKTOP */}
                        <Tooltip text={t('pages.constructionProject.projectCard.tooltip.responsibleSchuttflixEmployee')} position="bottom" size="sm">
                            <span className="text-subdued">
                                {responsibleEmployee.firstName} {responsibleEmployee.lastName}
                            </span>
                        </Tooltip>
                    </div>
                </div>
                {/* /DESKTOP VISIBLE */}

                {/* FAVORITE */}
                <div className="w-6 md:w-12 md:pr-2">
                    <div className="h-6 w-6 md:mx-auto" onClick={e => {
            if (mutation.isLoading) {
                return;
            }
            e.stopPropagation();
            handleUpdateFavoriteStatus();
        }}>
                        {isFavorite ? (<StarFilledIcon className={clsx(mutation.isLoading ? 'fill-icon-disabled' : 'fill-icon-accent')} size={24}/>) : (<StarIcon className={clsx(mutation.isLoading ? 'stroke-icon-disabled' : 'stroke-icon')} size={24}/>)}
                    </div>
                </div>
            </div>
        </div>);
};
