import { Card } from '../../Components/Card';
import { Section } from '../../Components/Section';
export function PermissionsPreview() {
    return (<Card title="3. Einstellungen & Berechtigungen" onEdit={() => console.log('clicked')}>
            <div className="font-copy-sm text-subdued">Martin Schüttflixmann</div>

            <hr className="my-6 border-divider"/>

            <Section title="Berechtigungen Kunde">
                <p>Kernfunktionen Kunde</p>
                <p>Projektgeschäft</p>
                <p>12343 Munsterstadt</p>
                <p>Deutschland</p>
                <p>Nordrhein-Westfalen</p>
            </Section>
        </Card>);
}
