import SimpleState from '@schuettflix/util-simple-state';
import { routerInstance } from './instances';
/**
 * This class manages query params and distributes their updates in a reactive way
 * through SimpleStates.
 * Its general purpose is to take one central SimpleState that contains **ALL** query params and
 * to create ONE SimpleState **FOR EACH** query param and to update the SimpleStates whenever the query param changes.
 */
class QueryParamManager {
    /**
     * This is a SimpleState that contains the current URLSearchParams.
     * It is the central source of truth for all query params.
     */
    searchParamsSimpleState = null;
    /**
     * This is the function that enables the QueryParamManager to update the URLSearchParams.
     * It is passed to the QueryParamManager via setup().
     */
    setSearchParams = () => { };
    /**
     * This is a map of all query params that are managed by the QueryParamManager.
     * The key is the query param name.
     * The value is a SimpleState that contains the current value of the query param.
     * The SimpleState is updated whenever the query param changes.
     * It reads the current value from the central searchParamsSimpleState.
     */
    queryParamSimpleStates = {};
    setup(searchParamsSimpleState, setSearchParams) {
        this.searchParamsSimpleState = searchParamsSimpleState;
        this.setSearchParams = setSearchParams;
    }
    getQueryParam(key) {
        if (!this.searchParamsSimpleState) {
            throw new Error('QueryParamManager not initialized');
        }
        if (this.queryParamSimpleStates[key]) {
            return this.queryParamSimpleStates[key];
        }
        const valueSimpleState = new SimpleState(this.searchParamsSimpleState.value.get(key) || '');
        this.queryParamSimpleStates[key] = valueSimpleState;
        this.searchParamsSimpleState.onUpdate(searchParams => {
            valueSimpleState.value = searchParams.get(key) || '';
        });
        return valueSimpleState;
    }
    setQueryParam(key, value) {
        if (!this.searchParamsSimpleState) {
            throw new Error('QueryParamManager not initialized');
        }
        this.setSearchParams(searchParams => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(key, value);
            if (value === '')
                newSearchParams.delete(key);
            return newSearchParams;
        });
    }
    getQueryParams() {
        if (!this.searchParamsSimpleState) {
            throw new Error('QueryParamManager not initialized');
        }
        const fromUrlSearchParams = (searchParams) => {
            const queryParams = {};
            searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });
            return queryParams;
        };
        const queryParamsSimpleState = new SimpleState(fromUrlSearchParams(this.searchParamsSimpleState.value));
        this.searchParamsSimpleState.onUpdate(searchParams => {
            queryParamsSimpleState.value = fromUrlSearchParams(searchParams);
        });
        return queryParamsSimpleState;
    }
    setQueryParams(queryParams) {
        if (!this.searchParamsSimpleState) {
            throw new Error('QueryParamManager not initialized');
        }
        this.setSearchParams(searchParams => {
            const newSearchParams = new URLSearchParams(searchParams);
            for (const key in queryParams) {
                const value = queryParams[key];
                newSearchParams.set(key, value);
                if (value === '')
                    newSearchParams.delete(key);
            }
            return newSearchParams;
        });
    }
}
const getHashSearchParams = () => {
    const queryPart = window.location.hash.split('?')[1];
    if (!queryPart) {
        return new URLSearchParams();
    }
    return new URLSearchParams(queryPart);
};
const setHashSearchParams = (newSearchParams) => {
    const newHash = `${window.location.hash.split('?')[0]}?${newSearchParams.toString()}`;
    if (window.location.hash !== newHash) {
        window.location.hash = newHash;
    }
};
let queryParamManager = null;
export const getQueryParamManager = () => {
    if (queryParamManager)
        return queryParamManager;
    const searchParamsSimpleState = new SimpleState(getHashSearchParams());
    const onSearchParamsChange = () => {
        searchParamsSimpleState.value = new URLSearchParams(window.location.hash.split('?')[1]);
    };
    const router = routerInstance.get();
    if (!router)
        throw new Error('Router not initialized');
    router.afterEach(onSearchParamsChange);
    window.addEventListener('hashchange', onSearchParamsChange);
    const setSearchParams = (updateSearchParams) => {
        searchParamsSimpleState.value = updateSearchParams(searchParamsSimpleState.value);
        setHashSearchParams(searchParamsSimpleState.value);
    };
    queryParamManager = new QueryParamManager();
    queryParamManager.setup(searchParamsSimpleState, setSearchParams);
    return queryParamManager;
};
