import { PRODUCT_CATEGORY_LABELS } from '../utils/ProductCategoryLabels';
import { useTranslation } from 'react-i18next';
import { ProductSummary } from './ProductSummary';
export function Transactions(props) {
    const { t } = useTranslation();
    return (<div className="flex flex-col gap-6">
            {props.transactions.map(transaction => (<div key={transaction.productId}>
                    <div className="font-copy-sm-strong">{t(PRODUCT_CATEGORY_LABELS[transaction.productCategory])}</div>
                    <ProductSummary transaction={transaction} financeSettings={props.financeSettings}/>
                </div>))}
        </div>);
}
