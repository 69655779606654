import isFuture from 'date-fns/is_future';
import { CHANGE_TRANSPORT_DELIVERY_DATE, UNPLAN_TRANSPORT, PLATFORM_UNPLAN_TRANSPORT, REPLAN_TRANSPORT, RETURN_TRANSPORT, PLATFORM_RETURN_TRANSPORT, FAIL_TRANSPORT, CANCEL_TRANSPORT, CANCEL_LOADING, PLATFORM_REVOKE_PREFERRED_STATUS, CHANGE_VEHICLE, CHANGE_CONFIRM_DELIVERY_IMAGE, CHANGE_TRANSPORT_LOADING_SHIPMENT, CHANGE_TRANSPORT_UNLOADING_SHIPMENT, PLATFORM_CONFIRM_TRANSPORT_LOADING_SHIPMENT, PLATFORM_CONFIRM_TRANSPORT_UNLOADING_SHIPMENT, PLATFORM_CHANGE_PRICE_SHIPMENT, CHANGE_TRANSPORT_LOAD_WINDOW_SHIPMENT, CHANGE_TRANSPORT_PRICE_SHIPMENT, CHANGE_TRANSPORT_FREIGHT_INFORMATION_SHIPMENT, UPDATE_ORIGINAL_DELIVERY_NOTE, UPDATE_ACCOMPANYING_DOCUMENT_NUMBER, UPDATE_ACCOMPANYING_DOCUMENTS, CONFIRM_UNLOADING_PER_TON, CONFIRM_UNLOADING_PER_TRANSPORT, UPDATE_LOADING_INFORMATION, UPDATE_UNLOADING_INFORMATION, CAPTURE_TRANSPORT, RECORD_WEIGHING_BY_DRIVER, } from '@/services/TransportActions';
import TransportService from '@/services/TransportService';
import { isDisposalTransport } from '@/services/Disposal/utils';
import { CONTEXT_PLATFORM, CONTEXT_CLIENT, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER, CONTEXT_SUPPLIER, } from '@/constants/context';
import { SfEditIcon, SfInfoIcon, SfReloadIcon, SfCancelIcon, SfVehicleIcon, SfWeightKgIcon, SfPdfIcon, SfSysPlusIcon, } from '@schuettflix/vue-components';
import { useAccompanyingDocumentNumber } from '@/services/Disposal/useAccompanyingDocumentNumber';
import { computed, ref } from 'vue';
import { ensureJSTimestamp } from '@/services/utils/date';
import { useI18n } from 'vue-i18n-composable';
import { useCan, useRouteContext } from '@/utils/composition-helper';
import { EventBus } from '@/services/EventBus';
import { useActions } from 'vuex-composition-helpers';
import { useRoute, useRouter } from 'vue-router/composables';
import { findRouteName } from '@/pages/routerUtils';
export const useTransportContextMenu = (transport) => {
    // setup stuff
    const { t } = useI18n();
    const { inRouteContext, routeContext } = useRouteContext();
    const { value: can } = useCan();
    const { setTransports: setTransportsForTransportPlan } = useActions('transportPlan', ['setTransports']);
    const { set: setTransportAction } = useActions('transportActions', ['set']);
    const router = useRouter();
    const route = useRoute();
    // helpers
    const isPlatform = computed(() => inRouteContext(CONTEXT_PLATFORM));
    const { isAccompanyingDocumentNumberAddable, isAccompanyingDocumentNumberUpdatable } = useAccompanyingDocumentNumber(transport);
    const isDiscardCheckinShipmentPending = ref(false);
    // handleContextMenuEntryClick
    const handleContextMenuEntryClick = ({ closeMenu, contextMenuEntry, }) => {
        if (!contextMenuEntry.skipCloseMenu)
            closeMenu();
        if ('action' in contextMenuEntry && contextMenuEntry.action)
            return openTransportAction(contextMenuEntry.action);
        contextMenuEntry.customAction?.();
    };
    const openTransportAction = async (key) => {
        setTransportAction({
            action: key,
            transport: transport.value,
            context: routeContext.value,
        });
    };
    const shouldContextMenuEntryBeShown = contextMenuEntry => {
        const isInAllowedRouteContext = contextMenuEntry => !('allowedRouteContexts' in contextMenuEntry) ||
            !contextMenuEntry.allowedRouteContexts ||
            inRouteContext(...contextMenuEntry.allowedRouteContexts);
        const hasTransportAbility = contextMenuEntry => {
            if (!('abilityName' in contextMenuEntry) || !contextMenuEntry.abilityName)
                return true;
            return can(contextMenuEntry.abilityName, 
            // converting to a transport that doesn't differentiate anymore for between transport types
            transport.value);
        };
        const isAdditionalConditionMet = contextMenuEntry => !('additionalCondition' in contextMenuEntry) ||
            !contextMenuEntry.additionalCondition ||
            contextMenuEntry.additionalCondition();
        return (isInAllowedRouteContext(contextMenuEntry) &&
            hasTransportAbility(contextMenuEntry) &&
            isAdditionalConditionMet(contextMenuEntry));
    };
    // contextMenuEntries
    const contextMenuEntries = computed(() => [
        {
            allowedRouteContexts: [CONTEXT_CARRIER_DRIVER, CONTEXT_PLATFORM],
            abilityName: 'weighingUpdateOriginalDeliveryNote',
            action: UPDATE_ORIGINAL_DELIVERY_NOTE,
            dataTestAttribute: 'transport-context-menu-update-delivery-note',
            icon: SfEditIcon,
            name: transport.value.weighingMethod === 'cbm'
                ? t('contextMenu.transport.updateOriginalDeliveryNoteCbmLabel')
                : t('contextMenu.transport.updateOriginalDeliveryNoteLabel'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CLIENT],
            abilityName: 'updateLoadingInformation',
            action: UPDATE_LOADING_INFORMATION,
            dataTestAttribute: 'transport-context-menu-edit-loading-information',
            icon: SfEditIcon,
            name: t('contextMenu.transport.editLoadingInformation'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CLIENT],
            abilityName: 'updateUnloadingInformation',
            action: UPDATE_UNLOADING_INFORMATION,
            dataTestAttribute: 'transport-context-menu-edit-unloading-information',
            icon: SfEditIcon,
            name: t('contextMenu.transport.editUnloadingInformation'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'updateDisposalTransportWeighing',
            action: CONFIRM_UNLOADING_PER_TON,
            dataTestAttribute: 'transport-context-menu-disposal-edit-unloading-information',
            icon: SfEditIcon,
            name: t('contextMenu.transport.editUnloadingInformation'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportUpdateTransportPriceShipment',
            action: PLATFORM_CHANGE_PRICE_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-change-price-as-platform',
            icon: SfEditIcon,
            name: t('contextMenu.transport.changePriceShipment'),
        },
        {
            allowedRouteContexts: [CONTEXT_CLIENT],
            abilityName: 'transportUpdateTransportPriceShipment',
            action: CHANGE_TRANSPORT_PRICE_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-change-price',
            icon: SfEditIcon,
            name: t('contextMenu.transport.changePriceShipment'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportChangeDeliveryWindow',
            dataTestAttribute: 'change-delivery-date',
            action: CHANGE_TRANSPORT_DELIVERY_DATE,
            icon: SfEditIcon,
            name: isDisposalTransport(transport.value)
                ? t('contextMenu.transport.changeDeliveryDateDisposal')
                : t('contextMenu.transport.changeDeliveryDate'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportUpdateLoadWindowShipment',
            action: CHANGE_TRANSPORT_LOAD_WINDOW_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-change-change-loading-window',
            icon: SfEditIcon,
            name: 'planningBase' in transport.value && transport.value.planningBase
                ? t(`contextMenu.transport.shipmentChange_${transport.value.planningBase}Time`)
                : t('contextMenu.transport.shipmentChangeLoadWindow'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportUpdateShipmentFreightInformation',
            action: CHANGE_TRANSPORT_FREIGHT_INFORMATION_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-change-freight-information',
            icon: SfEditIcon,
            name: t('contextMenu.transport.changeFreightInformationShipment'),
        },
        {
            allowedRouteContexts: [CONTEXT_CARRIER],
            abilityName: 'transportUnplan',
            customAction: () => {
                setTransportsForTransportPlan([transport.value]);
                router
                    .push({
                    name: findRouteName('transport-plan'),
                    params: { transportId: String(transport.value.id) },
                    query: route.query,
                })
                    .catch(() => {
                    // This is intentional
                });
            },
            dataTestAttribute: 'transport-context-menu-change-planning',
            icon: SfReloadIcon,
            disabled: !can('isAllowedToPlanTransport', transport.value),
            name: t('contextMenu.transport.changePlannedTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER],
            abilityName: 'transportUpdateLoadingShipment',
            action: CHANGE_TRANSPORT_LOADING_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-change-loading',
            icon: SfEditIcon,
            name: t('contextMenu.transport.shipmentChangeLoading'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER],
            abilityName: 'transportUpdateUnloadingShipment',
            action: CHANGE_TRANSPORT_UNLOADING_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-change-unloading',
            icon: SfEditIcon,
            name: t('contextMenu.transport.shipmentChangeUnloading'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportShipmentPlatformConfirmLoading',
            action: PLATFORM_CONFIRM_TRANSPORT_LOADING_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-confirm-loading-as-platform',
            icon: SfEditIcon,
            name: t('contextMenu.transport.platformShipmentConfirmLoading'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportShipmentPlatformConfirmDelivery',
            action: PLATFORM_CONFIRM_TRANSPORT_UNLOADING_SHIPMENT,
            dataTestAttribute: 'transport-context-menu-shipment-confirm-unloading-as-platform',
            icon: SfEditIcon,
            name: t('contextMenu.transport.platformShipmentConfirmUnloading'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER],
            abilityName: 'transportUnplan',
            action: isPlatform.value ? PLATFORM_UNPLAN_TRANSPORT : UNPLAN_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-unplan-transport',
            icon: SfReloadIcon,
            name: t('contextMenu.transport.unplanTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportRevokePreferredStatus',
            additionalCondition: () => !!transport.value.preferredUntil &&
                isFuture(new Date(ensureJSTimestamp(transport.value.preferredUntil))),
            action: PLATFORM_REVOKE_PREFERRED_STATUS,
            dataTestAttribute: 'transport-context-menu-revoke-preferred-status',
            icon: SfReloadIcon,
            name: t('contextMenu.transport.revokePreferredStatus'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER],
            abilityName: 'transportReplan',
            action: REPLAN_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-replan-transport',
            icon: SfReloadIcon,
            disabled: !can('isAllowedToPlanTransport', transport.value),
            name: t('contextMenu.transport.replanTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_CARRIER_DRIVER],
            abilityName: 'transportDiscard',
            customAction: async () => {
                if (isDiscardCheckinShipmentPending.value)
                    return;
                isDiscardCheckinShipmentPending.value = true;
                await TransportService.discardCheckinShipment(routeContext.value, transport.value.id);
                isDiscardCheckinShipmentPending.value = false;
            },
            skipCloseMenu: true,
            disabled: isDiscardCheckinShipmentPending.value,
            dataTestAttribute: 'transport-context-menu-discard-check-in',
            icon: SfReloadIcon,
            name: t('contextMenu.transport.discardCheckin'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER],
            abilityName: 'accessTransportRenew',
            action: isPlatform.value ? PLATFORM_RETURN_TRANSPORT : RETURN_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-return-transport',
            icon: SfCancelIcon,
            name: t('contextMenu.transport.returnTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER],
            abilityName: 'updateDisposalTransportAccompanyingDocumentNumber',
            additionalCondition: () => isAccompanyingDocumentNumberAddable.value,
            action: UPDATE_ACCOMPANYING_DOCUMENT_NUMBER,
            dataTestAttribute: 'transport-context-menu-add-accompanying-document-number',
            icon: SfEditIcon,
            name: t('contextMenu.transport.updateAccompanyingDocumentNumber'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER],
            abilityName: 'updateDisposalTransportAccompanyingDocumentNumber',
            additionalCondition: () => isAccompanyingDocumentNumberUpdatable.value,
            action: UPDATE_ACCOMPANYING_DOCUMENT_NUMBER,
            dataTestAttribute: 'transport-context-menu-update-accompanying-document-number',
            icon: SfEditIcon,
            name: t('contextMenu.transport.editAccompanyingDocumentNumber'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER],
            abilityName: 'updateDisposalTransportAccompanyingDocuments',
            action: UPDATE_ACCOMPANYING_DOCUMENTS,
            dataTestAttribute: 'transport-context-menu-update-accompanying-documents-and-images',
            icon: SfSysPlusIcon,
            name: t('contextMenu.transport.updateAccompanyingDocuments'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER],
            abilityName: 'transportRecordWeighing',
            additionalCondition: () => (!('billingMethod' in transport.value) ||
                !transport.value.billingMethod ||
                transport.value.billingMethod === 'weight') &&
                transport.value.type === 'disposal',
            action: CONFIRM_UNLOADING_PER_TON,
            dataTestAttribute: 'transport-context-menu-confirm-unloading-per-ton',
            icon: SfSysPlusIcon,
            name: t('contextMenu.transport.captureWeightingNote'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER],
            abilityName: 'transportRecordUnloadingSignature',
            additionalCondition: () => 'billingMethod' in transport.value &&
                transport.value.billingMethod === 'fixedPrice' &&
                transport.value.type === 'disposal',
            action: CONFIRM_UNLOADING_PER_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-confirm-unloading-per-transport',
            icon: SfSysPlusIcon,
            name: t('contextMenu.transport.captureTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CARRIER],
            abilityName: 'transportCapture',
            additionalCondition: () => transport.value.type !== 'disposal',
            action: CAPTURE_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-capture-shipment',
            icon: SfSysPlusIcon,
            name: t('contextMenu.transport.captureTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_CARRIER_DRIVER],
            abilityName: 'transportRecordLoadingWeighing',
            action: RECORD_WEIGHING_BY_DRIVER,
            dataTestAttribute: 'transport-context-record-weighing-by-driver',
            icon: SfWeightKgIcon,
            name: t('components.activityCards.delivery.checkedIn.actions.continueWeighing'),
        },
        {
            allowedRouteContexts: [CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER, CONTEXT_CLIENT, CONTEXT_PLATFORM],
            abilityName: 'transportFail',
            action: FAIL_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-fail-transport',
            icon: SfCancelIcon,
            name: t('contextMenu.transport.failTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_SUPPLIER],
            abilityName: 'weighingCancel',
            action: CANCEL_LOADING,
            dataTestAttribute: 'transport-context-menu-supplier-cancel-loading',
            icon: SfCancelIcon,
            name: t('contextMenu.transport.cancelLoading'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM, CONTEXT_CLIENT],
            abilityName: 'transportCancel',
            action: CANCEL_TRANSPORT,
            dataTestAttribute: 'transport-context-menu-cancel-transport',
            icon: SfCancelIcon,
            name: t('contextMenu.transport.cancelTransport'),
        },
        {
            allowedRouteContexts: [CONTEXT_CLIENT],
            abilityName: 'changePickupTransportVehicle',
            action: CHANGE_VEHICLE,
            dataTestAttribute: 'transport-context-menu-change-vehicle',
            icon: SfVehicleIcon,
            name: t('contextMenu.transport.changeVehicle'),
        },
        {
            additionalCondition: () => !!transport.value.deliveryNoteDocument && !!transport.value.deliveryNoteDocument.url,
            customAction: () => {
                if (transport.value.deliveryNoteDocument?.url)
                    window.location.href = transport.value.deliveryNoteDocument.url;
            },
            dataTestAttribute: 'transport-context-menu-download-delivery-note',
            icon: SfPdfIcon,
            name: t('contextMenu.transport.downloadDeliveryNote'),
        },
        {
            allowedRouteContexts: [CONTEXT_PLATFORM],
            abilityName: 'transportUpdateDriverConfirmDeliveryImage',
            action: CHANGE_CONFIRM_DELIVERY_IMAGE,
            dataTestAttribute: 'transport-context-menu-change-confirm-delivery-image',
            icon: SfReloadIcon,
            name: t('contextMenu.transport.changeConfirmDeliveryImage'),
        },
        {
            // eslint-disable-next-line vue/custom-event-name-casing
            customAction: () => EventBus.$emit('page.platformContact', true),
            dataTestAttribute: 'transport-context-menu-contact-platform',
            icon: SfInfoIcon,
            name: t('contextMenu.transport.contactSchuettflix'),
        },
    ]);
    return { handleContextMenuEntryClick, shouldContextMenuEntryBeShown, contextMenuEntries };
};
