import { encode, decode } from 'js-base64';
import Toaster from '@/services/Toaster';
import _clone from 'lodash/clone';
import _intersection from 'lodash/intersection';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import i18n from '@/i18n';

export default class FilterableListing {
    constructor({
        namespace,
        endpoint,
        defaultFilter = {},
        defaultRestriction = {},
        sortOptions = [],
        transformCallback = null,
        restrictionLabelRenderer = null,
        // NOTE: See `usePositionTabLabels` why the option `restrictionCountsEnabled` exists!
        restrictionCountsEnabled = true,
    }) {
        this._namespace = namespace;
        this.endpoint = endpoint;
        this._defaultFilter = defaultFilter;
        this._defaultRestriction = defaultRestriction;
        this._sortOptions = sortOptions;
        this._transformCallback = transformCallback;
        this._restrictionLabelRenderer = restrictionLabelRenderer;
        this._restrictionCountsEnabled = restrictionCountsEnabled;

        this._skipReAssembleFilter = false;
        this._componentInstance = null;
        this._filter = {};
        this._activeRestriction = null;
        this._isLoading = false;
        this._result = null;
        this._restrictions = {};
        this._cancelSource = null;
        this._countCancelSources = {};
        this._restrictionCounts = {};
    }

    /**
     * Wire component instance to the filter listing
     * @param componentInstance
     */
    pairComponent(componentInstance) {
        if (this._componentInstance) {
            throw new Error('Already paired with component instance');
        }

        this._componentInstance = componentInstance;

        // assemble filter from yrl
        this.detectActiveRestriction();
        this.loadPreset();
        // this.loadRestriction();

        if (this._restrictionCountsEnabled) {
            this.refreshRestrictionCounts();
        }
    }

    /**
     * Get endpoint
     * @returns {AbstractFilterableResource}
     */
    get endpoint() {
        return this._endpoint;
    }

    /**
     * Set endpoint
     * @param {AbstractFilterableResource} endpoint
     */
    set endpoint(endpoint) {
        this._endpoint = endpoint;
    }

    /**
     * Get namespace
     * @returns {string}
     */
    get namespace() {
        return this._namespace;
    }

    /**
     * Get namespace for restrictions
     */
    get restrictionNamespace() {
        return `${this.namespace}Forced`;
    }

    /**
     * Get a filter-save sort options
     * @returns {*}
     */
    get sortOptions() {
        return _intersection(this.endpoint.supportedSorts, this._sortOptions);
    }

    /**
     * Set sort options
     * @param {Array} sortOptions
     */
    set sortOptions(sortOptions) {
        this._sortOptions = sortOptions;
    }

    /**
     * Get the key of the active restriction
     * @returns {string}
     */
    get activeRestriction() {
        return this._activeRestriction;
    }

    /**
     * Get restriction configuration based on the active key
     * @returns {object}
     */
    get restriction() {
        return this._restrictions[this._activeRestriction];
    }

    /**
     * Get restrictions
     * @returns {object}
     */
    get restrictions() {
        return this._restrictions;
    }

    /**
     * Get default restriction configuration
     * @returns {object}
     */
    get defaultRestriction() {
        return this._defaultRestriction;
    }

    /**
     * Get restriction counts
     * @returns {object}
     */
    get restrictionCounts() {
        return this._restrictionCounts;
    }

    /**
     * Get filter
     * @returns {{}}
     */
    get filter() {
        return this._filter;
    }

    /**
     * Set filter
     * @param filter
     */
    set filter(filter) {
        this._filter = filter;
    }

    /**
     * Get default filter
     * @returns {object}
     */
    get defaultFilter() {
        return this._defaultFilter;
    }

    /**
     * Get a filter result
     * @returns {FilterResult}
     */
    get result() {
        return this._result;
    }

    /**
     * Get result items
     * @returns {array}
     */
    get resultItems() {
        return this._result ? this._result.items : [];
    }

    /**
     * Get result count
     * @returns {number}
     */
    get resultCount() {
        return this._result ? this._result.count : 0;
    }

    /**
     * Check if is loading
     * @returns {boolean}
     */
    get isLoading() {
        return this._isLoading;
    }

    /**
     * Add a restriction to the listing
     * @param {string} name
     * @param {object} filter
     * @returns {FilterableListing}
     */
    addRestriction(name, filter) {
        this._restrictions[name] = filter;
        return this;
    }

    /**
     * Event callback on route changed
     */
    onRouteChanged(onBeforeRefreshCallback = null) {
        if (this._skipReAssembleFilter) {
            this._skipReAssembleFilter = false;
            return;
        }

        // Stop reloading the page on child route navigations
        if (
            this.isQueryFilterActive(this.restrictionNamespace, this.restriction) &&
            this.isQueryFilterActive(this.namespace, this.filter)
        ) {
            return;
        }

        this.detectActiveRestriction();
        this.loadPreset();
        if (this._restrictionCountsEnabled) {
            this.refreshRestrictionCounts();
        }
        onBeforeRefreshCallback !== null && onBeforeRefreshCallback();
        this.refreshList();
    }

    /**
     * Refresh the list
     * @param isInitial
     * @param resetPagination
     * @param skipApplyFilter
     * @returns {Promise<void>}
     */
    async refreshList(isInitial = false, resetPagination = false, skipApplyFilter = false) {
        this._isLoading = true;

        // check if we have to reset the pagination
        if (resetPagination) {
            this._filter.page = 1;
        }

        // persist filter
        this.persistFilter(this.namespace, this.filter, this._defaultFilter);
        this.restriction && this.persistFilter(this.restrictionNamespace, this.restriction, this._defaultFilter);
        this._skipReAssembleFilter = skipApplyFilter;

        // cancel previous request
        this._cancelSource && this._cancelSource.cancel('canceled-previous-call');
        this._cancelSource = this.endpoint.createCancelTokenSource();

        try {
            const result = await this.endpoint.filter(this.filter, null, null, this._cancelSource, {
                ...this._defaultRestriction,
                ...this.restriction,
            });

            // TODO: setup transform in component
            if (this._transformCallback) {
                result.transform(this._transformCallback.bind(this));
            }

            this._result = result;

            if (isInitial === true) {
                this._filter = {
                    ...this._filter,
                    ...result.appliedFilter,
                };
            }
        } catch (err) {
            if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                this._componentInstance.$logger().error(err);
                Toaster.error(i18n.t(err.message));
            }
        }

        this._isLoading = false;
    }

    /**
     * Select restriction by key
     * @param restrictionKey
     */
    selectRestriction(restrictionKey) {
        if (!this._restrictions[restrictionKey]) {
            throw new Error(`restrictionKey "${restrictionKey}" is not available in current listing`);
        }

        this._activeRestriction = restrictionKey;

        this._componentInstance.$router
            .push({
                name: this._componentInstance.$router.currentRoute.name,
                query: {
                    ...this._componentInstance.$router.currentRoute.query,
                    [this.restrictionNamespace]: this.assembleQueryFilter(this._restrictions[this._activeRestriction]),
                },
            })
            .catch(() => {});
    }

    /**
     * Check if the current url filter matches one of the provided restriction options aka updateActivePredefinedFilter
     */
    detectActiveRestriction() {
        this._activeRestriction = Object.keys(this._restrictions)[0];
        Object.keys(this._restrictions).forEach(key => {
            if (this.isQueryFilterActive(this.restrictionNamespace, this._restrictions[key])) {
                this._activeRestriction = key;
            }
        });
    }

    /**
     * Apply filter from the url to the current filter instance
     */
    loadPreset() {
        this.filter = this.assembleFilter(this.namespace, this._defaultFilter);
    }

    /**
     * TODO: we might not need this, since the detect active restriction takes care of an up to date selection of the restriction
     *       Read only restrictions (forced filters), prevent DEVs from loading a specific page with a custom forced filter in place.
     *       It makes no sense to load a restriction from the url, since they are not modified and have to be predefined anyway...
     */
    loadRestriction() {
        throw new Error('Not implemented');
        // const activeRestriction = this._activeRestriction ? this._restrictions[this._activeRestriction] : {};
        // this.assembleFilter(this.restrictionNamespace, activeRestriction);
    }

    /**
     * Update by pagination
     * @param number
     * @returns {Promise<void>}
     */
    async updatePageNumber(number) {
        this._filter.page = number;
        await this.refreshList(true);
    }

    /// Persistent filter mixin

    assembleFilter(name, filter) {
        const loadedQuery = this._componentInstance.$router.currentRoute.query[name];

        if (!loadedQuery) {
            return filter;
        }

        try {
            // Using public base64 implementation, to hande characters outside the Latin1 range.
            // https://stackoverflow.com/a/45725439
            return JSON.parse(decode(loadedQuery));
        } catch (e) {
            return filter;
        }
    }

    /**
     * Store a given filter to the url
     * @param name
     * @param filter
     * @param defaultFilter
     */
    persistFilter(name, filter, defaultFilter) {
        const query = _clone(this._componentInstance.$router.currentRoute.query);

        if (_isEqual(filter, defaultFilter)) {
            delete query[name];
        } else {
            query[name] = this.assembleQueryFilter(filter);
        }

        this._componentInstance.$router
            .replace({
                path: this._componentInstance.$router.currentRoute.path,
                query: query,
            })
            .catch(() => {});
    }

    /**
     * Convert object to base64
     * @param filter
     * @returns {string}
     */
    assembleQueryFilter(filter) {
        // Using public base64 implementation, to hande characters outside the Latin1 range.
        // https://stackoverflow.com/a/45725439
        return encode(JSON.stringify(filter));
    }

    /**
     * Check for active filter
     * @param name
     * @param filter
     * @returns {boolean}
     */
    isQueryFilterActive(name, filter) {
        return this._componentInstance.$router.currentRoute.query[name] === this.assembleQueryFilter(filter);
    }

    /**
     * Check if the given filter set exists
     * @param name
     * @returns {boolean}
     */
    isQueryFilterSet(name) {
        return !!this._componentInstance.$router.currentRoute.query[name];
    }

    /**
     * Refresh counts of restrictions
     */
    refreshRestrictionCounts() {
        Object.keys(this.restrictions).forEach(async key => {
            this._componentInstance.$set(
                this._restrictionCounts,
                key,
                await this.fetchFilterCount(`restriction-${key}`, this.restrictions[key])
            );
        });
    }

    /**
     * Fetch the count for a given filter
     * @param type
     * @param key
     * @param filter
     * @returns {Promise<*>}
     */
    async fetchFilterCount(key, filter) {
        this._countCancelSources[key] && this._countCancelSources[key].cancel('canceled-previous-preferred-count-call');
        this._countCancelSources[key] = this.endpoint.createCancelTokenSource();

        try {
            const result = await this.endpoint.filter(filter, null, null, this._countCancelSources[key], {
                ...this._defaultRestriction,
                countOnly: true,
            });

            return result.count;
        } catch (err) {
            if (err.code !== 400 && err.message !== 'canceled-previous-preferred-count-call') {
                this._componentInstance.$logger().error(err);
            }
        }
    }

    get restrictionsLabelSet() {
        const tabs = {};

        Object.keys(this.restrictions).forEach(key => {
            let label = key;

            if (this._restrictionLabelRenderer !== null) {
                label = this._restrictionLabelRenderer(key, this.restrictionCounts[key]);
            }

            tabs[key] = label;
        });

        return tabs;
    }

    get cleanedQuery() {
        return _omit(this._componentInstance.$router.currentRoute.query, [this.namespace, this.restrictionNamespace]);
    }
}
