<template>
    <component :is="getTripComponent()" />
</template>

<script>
import { CONTEXT_CLIENT, CONTEXT_CARRIER_DRIVER } from '@/constants/context';
import routeContext from '@/plugins/mixins/routeContext';
import { useLd } from '@/services/LaunchDarkly';

import CarrierActiveTrips from './CarrierActiveTrips.deprecated';
import PickupActiveTrips from './PickupActiveTrips';
import DriverTransportList from './pages/DriverTransportList.vue';

export default {
    name: 'TransportListPage',
    components: {
        CarrierActiveTrips,
        PickupActiveTrips,
    },
    mixins: [routeContext],
    setup() {
        const isTransportServiceEnabled = useLd('transport-service-enabled');

        return { isTransportServiceEnabled };
    },
    methods: {
        getTripComponent() {
            if (this.inRouteContext(CONTEXT_CARRIER_DRIVER) && this.$can('listActiveTrips')) {
                if (this.isTransportServiceEnabled) return DriverTransportList;
                return CarrierActiveTrips;
            }
            if (this.inRouteContext(CONTEXT_CLIENT) && this.$can('listActivePickupTrips')) {
                if (this.isTransportServiceEnabled) return DriverTransportList;
                return PickupActiveTrips;
            }

            return 'div';
        },
    },
};
</script>
