<template>
    <div v-if="position" class="project-position-info-block" :class="{ card: isCard }">
        <div class="font-copy-sm-strong mb-6">
            {{ $t('components.projectPositionInfoBlock.headline') }}
        </div>

        <div class="flex justify-between">
            <SfInfoWithContext :context="$t('components.projectPositionInfoBlock.projectNameLabel')" important>
                <span>{{ position.project.name }}</span>
            </SfInfoWithContext>
            <SfInfoWithContext
                :context="$t('components.projectPositionInfoBlock.projectPositionNumberLabel')"
                important
                class="items-end text-left"
            >
                <span class="font-numbers-md-strong">
                    {{ position.number }}
                </span>
            </SfInfoWithContext>
        </div>

        <footer>
            <SfLink
                v-if="isPlatformAdministrator"
                class="mt-6"
                bold
                type="secondary"
                size="md"
                @click="openProjectPosition()"
            >
                {{ $t('components.projectPositionInfoBlock.openProjectPosition') }}
            </SfLink>
        </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routeContext from '@/plugins/mixins/routeContext';

import ProjectPositionApi from '@/services/Api/ProjectPosition';
import { CONTEXT_CARRIER, CONTEXT_CLIENT, CONTEXT_PLATFORM, CONTEXT_SUPPLIER } from '@/constants/context';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { navigationFailure } from '@/services/utils/router';

import { SfLink, SfInfoWithContext } from '@schuettflix/vue-components';
import { CONSTRUCTION_PROJECT_ADMIN_LEGACY_POSITION_DETAILS_ROUTE } from '@/modules/constructionProjects/pages/routes';

export default {
    name: 'ProjectPositionInfoBlock',
    components: {
        SfInfoWithContext,
        SfLink,
    },
    mixins: [routeContext],
    props: {
        projectPositionId: {
            type: Number,
            default: null,
        },
        isCard: {
            type: Boolean,
            default: false,
        },
        projectPositionProp: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            projectPosition: null,
        };
    },
    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
        position() {
            return this.projectPositionProp || this.projectPosition;
        },
    },
    async created() {
        await this.refreshProjectPosition();
    },
    methods: {
        async refreshProjectPosition() {
            if (!this.projectPositionId) return;
            if (this.projectPositionProp) {
                this.projectPosition = this.projectPositionProp;
                this.$emit('loaded', true);
                return;
            }

            try {
                this.projectPosition = await ProjectPositionApi.getOneById(this.projectPositionId);
                this.$emit('loaded', true);
            } catch (err) {
                this.$emit('loaded', false);
                this.$logger().error(err);
            }
        },

        openProjectPosition() {
            let routeName = null;
            let routeParams = {};
            let routeQuery = this.$router.currentRoute.query;

            if (this.inRouteContext(CONTEXT_CARRIER)) {
                routeName = this.$root.findRouteName('logistics__project-position-list');
                routeQuery = {
                    projectPosition: assembleQueryFilter({
                        search: this.projectPosition.number,
                    }),
                };
            }

            if (this.inRouteContext(CONTEXT_SUPPLIER)) {
                routeName = 'materials__project-position-list';
                routeQuery = {
                    projectPosition: assembleQueryFilter({
                        search: this.projectPosition.number,
                    }),
                };
            }

            if (this.inRouteContext(CONTEXT_PLATFORM, CONTEXT_CLIENT)) {
                routeName = this.$root.findRouteName(CONSTRUCTION_PROJECT_ADMIN_LEGACY_POSITION_DETAILS_ROUTE);
                routeParams = {
                    id: this.projectPosition.project.constructionProjectId,
                    positionId: this.projectPositionId,
                };
            }

            this.$router
                .push({
                    name: routeName,
                    params: routeParams,
                    query: routeQuery,
                })
                .catch(navigationFailure);
        },
    },
};
</script>

<style lang="scss" scoped>
.project-position-info-block__body {
    display: grid;
    grid-template-columns: 3fr 2fr;
    row-gap: 3px;

    > *:nth-child(even) {
        text-align: right;
    }
}

.project-position-info-block__button {
    margin-top: 15px;
}
</style>
