<template>
    <button :disabled="disabled" class="order-type-item-v2 card card--hovered card--focussed" @click="$emit('click')">
        <div
            :class="[
                'order-type-item-v2__container',
                { 'order-type-item-v2__container--break': additionalIconProperties },
                { 'order-type-item-v2__container--no-icon': external },
            ]"
        >
            <component
                :is="icon"
                v-if="!external"
                :class="[
                    'order-type-item-v2__icon',
                    { 'order-type-item-v2__icon--default': !additionalIconProperties },
                ]"
                v-bind="additionalIconProperties"
            />
            <template v-if="external">
                <div class="flex flex-1 flex-col items-baseline">
                    <p class="font-copy-sm-strong pb-1">
                        {{ $t('pages.homeV2.allGenericOrders') }}
                    </p>
                    <p class="font-copy-sm text-subdued">
                        {{ $t('pages.platformTools.lookerProductSearch.description') }}
                    </p>
                </div>
                <SfExternalLinkIcon size="xxs" />
            </template>
            <template v-else>
                <Words block small bold class="text-left" v-html="title" />
                <ArrowIcon class="order-type-item-v2__arrow-icon" />
            </template>
        </div>
    </button>
</template>

<script>
import Words from '@/components/Typography/Words';
import Card from '@/components/Layout/Card';

import ArrowIcon from '@/assets/icons/regular/arrow-right-bold.svg';
import DeliveryIcon from '@/assets/icons/orderItems/delivery.svg';
import DisposalIcon from '@/assets/icons/orderItems/disposal.svg';
import PickupIcon from '@/assets/icons/orderItems/pickup.svg';
import ProjectOrderIcon from '@/assets/icons/orderItems/project-order.svg';
import ShipmentOrderIcon from '@/assets/icons/orderItems/shipment-order.svg';
import TelephoneIcon from '@/assets/icons/orderItems/telephone.svg';
import { SfExternalLinkIcon } from '@schuettflix/vue-components';

export default {
    name: 'OrderTypeItemV2',

    components: {
        Words,
        Card,

        ArrowIcon,
        DeliveryIcon,
        DisposalIcon,
        PickupIcon,
        ProjectOrderIcon,
        ShipmentOrderIcon,
        TelephoneIcon,
        SfExternalLinkIcon,
    },

    props: {
        icon: {
            type: String,
            default: 'DeliveryIcon',
            validator: value =>
                [
                    'DeliveryIcon',
                    'DisposalIcon',
                    'PickupIcon',
                    'TelephoneIcon',
                    'ProjectOrderIcon',
                    'ShipmentOrderIcon',
                ].includes(value),
        },
        fixSizedIcon: {
            type: Boolean,
            default: null,
        },
        iconWidth: {
            type: [String, Number],
            default: null,
        },
        iconHeight: {
            type: [String, Number],
            default: null,
        },
        title: {
            type: String,
            default: 'Title',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        external: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        additionalIconProperties() {
            const props = {};
            if (this.iconWidth) {
                props.width = this.iconWidth;
            }
            if (this.iconHeight) {
                props.height = this.iconHeight;
            }
            return props;
        },
    },
};
</script>

<style lang="scss">
.order-type-item-v2__container {
    display: grid;
    grid-template-columns: 3fr 7fr auto;
    align-items: center;
    gap: 10px;

    &--break {
        grid-template-columns: 1fr 7fr auto;
    }

    &--no-icon {
        display: flex;
    }
}

.order-type-item-v2__icon {
    &--default {
        height: 42px;
        max-width: 65px;
        width: 100%;
    }
}

.order-type-item-v2__arrow-icon {
    height: 14px;
}
</style>
