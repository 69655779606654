import OrganizationAccountingPage from './OrganizationAccountingPage.vue';
export default {
    path: 'accounting',
    name: 'accounting',
    component: OrganizationAccountingPage,
    props: true,
    meta: {
        noBackArrow: true,
        requiredAbilities: ['haveReceipts'],
    },
};
