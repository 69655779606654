import { MessageBox } from '@schuettflix/react-components';
import { Card } from '../../Components/Card';
import { Section } from '../../Components/Section';
export function AccountingPreview() {
    return (<Card title="2. Buchhaltung & E-Mail-Präferenzen" onEdit={() => console.log('clicked')}>
            <div>
                <MessageBox type="warning" className="mb-6 mt-4">
                    Bitte ergänze die fehlenden Angaben, um die Organisation erstellen zu können.
                </MessageBox>

                <Section title="Abrechnungsbedingungen">
                    <p>14 Tage netto</p>
                    <p>Pro Bestellung bei Abschluss</p>
                </Section>

                <hr className="my-6 border-divider"/>

                <Section title="E-Mail-Präferenzen">
                    <div>
                        <p>Sofort</p>
                        <p>Sofort</p>
                    </div>
                    <div>
                        <p>Email</p>
                        <p>Email</p>
                    </div>
                </Section>
            </div>
        </Card>);
}
