import { Transactions } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/components/Transactions';
import { useGenericPositionOverviewById } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useGenericPositionOverviewById';
import { PRODUCT_GROUP_TYPE_SCHEMA, } from '@/modules/constructionProjects/api/position-service-interfaces';
import { getFrancoOverviewTransactions } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/utils/getFrancoOverviewTransactions';
export function PositionOverviewContent({ positionId, positionProductGroupType }) {
    const { data } = useGenericPositionOverviewById(positionId);
    const organization = data?.organizations[0];
    if (!organization) {
        return null;
    }
    const isFranco = positionProductGroupType === PRODUCT_GROUP_TYPE_SCHEMA.enum.FRANCO;
    const transactions = isFranco
        ? getFrancoOverviewTransactions(organization.transactions)
        : organization.transactions;
    return <Transactions transactions={transactions} financeSettings={organization.financeSettings}/>;
}
