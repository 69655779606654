import { GetPositionDetailsResponseSchema } from '@/modules/constructionProjects/api/position-service-interfaces';
import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
export const getGenericPositionDetailsById = async (positionId) => {
    return GenericPositionHTTPClient.get(`/v1/position/${positionId}/details`).then(res => GetPositionDetailsResponseSchema.parse(res.data));
};
export function getGenericPositionDetailsByIdKey(positionId) {
    return ['generic-positions', 'details', positionId];
}
export function getGenericPositionDetailsByIdQuery(positionId) {
    return {
        queryKey: getGenericPositionDetailsByIdKey(positionId),
        queryFn: () => getGenericPositionDetailsById(positionId),
        refetchOnWindowFocus: false,
        suspense: true,
    };
}
export function invalidateGetGenericPositionDetailsByIdQuery(queryClient, positionId) {
    queryClient.invalidateQueries(getGenericPositionDetailsByIdKey(positionId));
}
