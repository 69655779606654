import { OrganizationForm } from './Organization/OrganizationForm';
export default [
    {
        path: 'magic',
        name: 'magic',
        component: OrganizationForm,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
        },
    },
];
